<template>
    <div>
        <Navigation/>
        <v-main>
            <v-container v-if="$acl.check('isUserPremium')">
                <v-row>
                    <v-col cols="12">
                        <Survey v-if="surveyReady" v-model="survey" @saved="save"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>
<script>
import Survey from '@/components/surveys/Form'
export default {
    components: {
        Survey,
    },
    data () {
        return {
            survey: {},
            surveyReady: false,
        }
    },
    mounted () {
        this.fetchSurvey()
    },
    methods: {
        fetchSurvey() {
            return this.$store.dispatch('surveyRequest', {id: 2, with: ['survey_questions']})
                .then(result => {
                    this.survey = result.data
                    this.fetchLastSurveySession()
                        .then(sessions => {
                            if (sessions.length) {
                                sessions[0].survey_responses.forEach(response => {
                                    let question = this.survey.survey_questions.find(q => q.id == response.survey_question_id)
                                    let resp = response.response
                                    if (question) {
                                        if (question.type == 'partner') {
                                            let partner = question.choices.find(p => p.name == resp)
                                            if (partner) resp = partner.id
                                        }
                                        this.$set(question, 'response', resp)
                                    }
                                })
                            }
                            this.surveyReady = true
                            // this.survey = survey
                        })
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        fetchLastSurveySession() {
            return new Promise((resolve, reject) => {
                let queries = {
                    page: 1,
                    per_page: 1,
                    sort: ['created_at'],
                    direction: 'desc',
                    search: JSON.stringify({user_id: this.$store.getters.getProfile.id}),
                    with: ['survey_responses'],
                }
                this.$store.dispatch('surveySessionsRequest', queries)
                    .then(result => {
                        resolve(result.data)
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.$store.dispatch('setErrors', err.response.data)
                        reject()
                    })
            })
        },
        save() {
            this.$router.push({name: 'Home'})
        }
    }
}
</script>
<template>
    <v-form class="pl-8 mt-12 mb-12" ref="reasonForm" v-on:submit.prevent="onSubmit">
        <v-radio-group @change="editedRegistrationProcess.partner.id = ''" :rules="[rules.required]"
            v-model="editedRegistrationProcess.reason">
            <v-radio label="Je suis envoyé.e par la Ligue contre le cancer" value="ligue"></v-radio>

            <v-radio label="Ma couverture santé prévoyance est" value="healthinsurance"></v-radio>
            <v-radio-group :rules="isHealthinsurance ? [rules.required] : []" class="mt-0 ml-12"
                v-model="editedRegistrationProcess.partner.id">
                <v-radio :disabled="!isHealthinsurance" v-for="partner in healthInsurancePartners" :key="partner.id"
                    :label="partner.name" :value="partner.id"></v-radio>
            </v-radio-group>

            <v-radio label="Je suis retraité.e et ma complémentaire retraite est" value="retreat"></v-radio>
            <v-radio-group :rules="isRetreat ? [rules.required] : []" class="mt-0 ml-12"
                v-model="editedRegistrationProcess.partner.id">
                <v-radio :disabled="editedRegistrationProcess.reason != 'retreat'" v-for="partner in retreatPartners"
                    :key="partner.id" :label="partner.name" :value="partner.id"></v-radio>
            </v-radio-group>

            <v-radio label="Mon employeur est" value="employer"></v-radio>
            <v-radio-group class="mt-0 ml-12" :rules="isEmployer ? [rules.required] : []"
                v-model="editedRegistrationProcess.partner.id">
                <v-radio :disabled="!isEmployer" v-for="partner in employerPartners" :key="partner.id" :label="partner.name"
                    :value="partner.id"></v-radio>
            </v-radio-group>
            <v-radio label="Autres" value="other"></v-radio>
        </v-radio-group>
        <v-btn color="primary" @click="saveForm"> Valider </v-btn>
        <v-btn text @click="cancel"> Annuler </v-btn>
    </v-form>
</template>

<script>
export default {
    props: {
        registration_process: { type: Object, required: false },
    },
    data() {
        return {
            allPartners: [],
            editedRegistrationProcess: {
                partner: {}
            },
            rules: { required: value => !!value || 'Champ requis' },
        }
    },
    mounted() {
        this.getPartners()
    },
    computed: {
        isLigue() {
            return this.editedRegistrationProcess.reason == "ligue"
        },
        isHealthinsurance() {
            return this.editedRegistrationProcess.reason == "healthinsurance"
        },
        isEmployer() {
            return this.editedRegistrationProcess.reason == "employer"
        },
        isRetreat() {
            return this.editedRegistrationProcess.reason == "retreat"
        },
        liguePartners() {
            return this.allPartners.filter(p => p.support_type == 'ligue')
        },
        healthInsurancePartners() {
            return this.allPartners.filter(p => p.support_type == 'healthinsurance')
        },
        employerPartners() {
            return this.allPartners.filter(p => p.support_type == 'employer')
        },
        retreatPartners() {
            return this.allPartners.filter(p => p.support_type == 'retreat')
        },
    },
    methods: {
        getPartners() {
            this.$store.dispatch('partnersRequest', {})
                .then((result) => this.allPartners = result.data)
                .catch(() => this.$store.dispatch('setErrors', 'Error while fetching partners'))
        },
        saveForm() {
            this.$store.dispatch('updateRegistrationProcessReason', this.editedRegistrationProcess)
            this.$emit('savedForm', this.editedRegistrationProcess)
        },
        cancel() {
            this.$emit('cancelUpdateReason')
            this.editedRegistrationProcess = { 
                partner: {}
            }
        }
    }
}
</script>
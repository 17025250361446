var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users"},[_c('Navigation'),_c('v-main',[(_vm.$acl.not.check('isProPremium'))?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('NotPremiumCard')],1)],1)],1):_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":[
                            {
                                text: 'Créé le',
                                align: 'center',
                                value: 'created_at',
                            },
                            {
                                text: 'Code',
                                align: 'center',
                                value: 'code',
                                sortable: false
                            },
                            {
                                text: 'Nom',
                                align: 'start',
                                value: 'lastname',
                            },
                            {
                                text: 'Prénom',
                                align: 'start',
                                value: 'firstname',
                            },
                            {
                                text: 'Email',
                                align: 'start',
                                value: 'email',
                            },
                            {
                                text: 'Dernier QR Info',
                                align: 'center',
                                value: 'last_qpr',
                            },
                            {
                                text: 'Dernier QSAT',
                                align: 'center',
                                value: 'last_qsat',
                            },
                            {
                                text: 'Nb inscriptions',
                                align: 'center',
                                value: 'subscriptions_count',
                            },
                            {
                                text: 'Nb présences',
                                align: 'center',
                                value: 'presences_count',
                            },
                            {
                                text: 'Nb absences',
                                align: 'center',
                                value: 'absences_count',
                            },
                            {
                                text: 'Nb désinscriptions',
                                align: 'center',
                                value: 'unsubscriptions_count',
                            } ],"items":_vm.users,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"no-data-text":"Aucune donnée","hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"click:row":function (item) { return _vm.$router.push({ name: 'ProUser', params: {id: item.id} }); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{attrs:{"elevation":"4","color":"secondary"}},[_c('v-card-text',[_c('v-row',{staticClass:"headline mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"white--text mr-5",attrs:{"x-large":""}},[_vm._v("account_circle")])],1),_c('v-col',{staticClass:"font-weight-bold white--text text-no-wrap"},[_vm._v("Mes adhérents")])],1),_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{staticStyle:{"min-width":"300px"}},[_c('v-text-field',{attrs:{"clearable":"","flat":"","solo":"","hide-details":"","prepend-inner-icon":"search","label":"Rechercher"},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", $$v)},expression:"search.name"}})],1),_c('v-col',{staticStyle:{"min-width":"300px"}},[_c('v-autocomplete',{attrs:{"items":_vm.allWorkshops,"item-text":"name","item-value":"id","label":"Atelier","loading":_vm.workshopsLoading,"flat":"","solo":"","clearable":"","hide-details":"","no-data-text":"Aucun atelier trouvé"},model:{value:(_vm.search.workshop_id),callback:function ($$v) {_vm.$set(_vm.search, "workshop_id", $$v)},expression:"search.workshop_id"}})],1),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('DD/MM/YYYY'))+" ")]}},{key:"item.code",fn:function(ref){
                        var item = ref.item;
return [(!item.member_code)?_c('span',[_vm._v(_vm._s(item.code))]):_c('span',[_vm._v(_vm._s(item.member_code))])]}},{key:"item.last_qpr",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_qpr ? _vm.$moment(item.last_qpr.created_at).format('DD/MM/YYYY') : '')+" ")]}},{key:"item.last_qsat",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_qsat ? _vm.$moment(item.last_qsat.created_at).format('DD/MM/YYYY') : '')+" ")]}}])}),(!_vm.loading)?_c('v-row',{staticClass:"mt-5",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{staticClass:"paginate",attrs:{"items":[
                                    {value: 20, text: '20'},
                                    {value: 50, text: '50'},
                                    {value: -1, text: 'Tous'} ],"solo":"","suffix":"résultats par page","hide-details":""},model:{value:(_vm.options.itemsPerPage),callback:function ($$v) {_vm.$set(_vm.options, "itemsPerPage", $$v)},expression:"options.itemsPerPage"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mr-1",attrs:{"fab":"","color":"primary","disabled":_vm.options.page <= 1},on:{"click":_vm.prevPage}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("keyboard_arrow_left")])],1),_c('span',{staticClass:"mx-4 grey--text"},[_vm._v(" Page "+_vm._s(_vm.options.page)+" sur "+_vm._s(_vm.lastPage)+" ")]),_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","color":"primary","disabled":_vm.options.page == _vm.lastPage},on:{"click":_vm.nextPage}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("keyboard_arrow_right")])],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="profile">
        <Navigation/>
        <v-main>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <UserDetails v-if="profile" v-model="profile" :color="$acl.check('isPro') || $acl.check('isProPremium') ? 'secondary': 'primary'"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>
<script>
import UserDetails from '@/components/users/Details'

export default {
    components: {
        UserDetails,
    },
    data() {
        return {
            profile: null,
        }
    },
    mounted() {
        this.getUser()
    },
    methods: {
        getUser() {
            this.$store.dispatch('userRequest', {id: this.$store.getters.getProfile.id})
                .then(result => {
                    this.profile = result.data
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
    }
}
</script>

<template>
    <div class="d-flex flex-column text-no-wrap text-uppercase text-center">
        <template v-if="$acl.check('isAuth') && profile">
            <span :class="{'grey--text': $vuetify.theme.dark}">Bonjour {{ profile.firstname }}</span>

            <v-menu offset-y auto content-class="my-menu">
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" class="title">
                        Mon compte
                    </v-btn>
                </template>
                <v-list class="text-uppercase text-center text-no-wrap">
                    <v-list-item v-if="$acl.not.check('isUserPremium') && $acl.not.check('isProPremium')">
                        <v-btn color="primary" :to="{name: 'Payment', query: {subscription: 1}}" class="mx-auto">
                            Adhérer
                        </v-btn>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Profile' }">
                        <v-list-item-title>Mes coordonnées</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Payments' }">
                        <v-list-item-title>Mes paiements</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="$acl.check('isUser')" :to="{ name: 'Meetings' }">
                        <v-list-item-title>Mes séances</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="$acl.check('isUserPremium')" :to="{ name: 'ProfileSurvey' }">
                        <v-list-item-title>
                            <v-icon class="ml-n4 mr-2" :color="$vuetify.theme.dark ? 'primary' : 'secondary'" size="30">warning</v-icon>
                            Questionnaire de PROFIL
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="$acl.check('isUserPremium')" :to="{ name: 'SatisfactionSurvey' }">
                        <v-list-item-title>
                            <v-icon class="mr-2" :color="$vuetify.theme.dark ? 'primary' : 'secondary'" size="30">help</v-icon>
                            Questionnaire de Satisfaction
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="$acl.check('isPro')" :to="{name: 'ProWorkshops'}">
                        <v-list-item-title>Mes ateliers</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="$acl.check('isPro')" :to="{name: 'ProUsers'}">
                        <v-list-item-title>Mes adhérents</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'Payment'}">
                        <v-list-item-title>Faire un don</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item>
                        <v-btn color="primary" :to="{name: 'Payment'}" class="mx-auto">
                            Faire un don
                        </v-btn>
                    </v-list-item> -->
                    <v-divider/>
                    <v-list-item @click.native="logout" to="#">
                        <v-list-item-title>Se déconnecter</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <router-link :class="{'router-link text-no-underline': true, 'grey--text': $vuetify.theme.dark}" to="" @click.native="logout">Se déconnecter</router-link>
        </template>

        <template v-else>
            <span :class="{'grey--text': $vuetify.theme.dark}">Bonjour, identifiez-vous</span>
            <router-link :class="{'router-link text-no-underline title':true, 'white--text': $vuetify.theme.dark}" active-class="primary--text" :to="{name: 'login'}">Connexion</router-link>
            <router-link :class="{'router-link text-no-underline': true, 'grey--text': $vuetify.theme.dark}" active-class="primary--text" :to="{name: 'register'}">Créer un compte</router-link>
        </template>
    </div>
</template>
<style scoped>
    .my-menu {
        max-height: 400px !important;
    }
</style>
<script>
export default {
    props: {
        // 
    },
    data () {
        return {
            // 
        }
    },
    computed: {
        profile() {
            return this.$store.getters.getProfile
        }
    },
    mounted() {
        // 
    },
    methods: {
        logout() {
            this.$store.dispatch('authLogout')
                .then(()=>{
                    this.$router.push('/login')
                })
        },
    }

}
</script>
<template>
    <div class="workshops">
        <Navigation/>
        <v-main>
            <v-container>
                <v-dialog v-model="showHelp" max-width="80%">
                    <v-card>
                        <v-card-title class="justify-end">
                            <v-btn icon @click="showHelp = false"><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text>
                            <div class="body-1 text-justify">
                                <p>
                                    <b>La méthode</b> <oncogite/> est le fruit d’une solide <b>expertise en neurosciences</b> et de <b>trois années d’expérience</b> d’animation d’ateliers spécialement dédiés à la prise en charge de patients atteints d’un cancer et présentant des troubles cognitifs liés aux traitements. 
                                </p>
                                <p>
                                    Elle repose sur la proposition d’exercices spécialement <b>créés pour ces patients</b> afin de les amener à retrouver une certaine performance cognitive. Un parcours de remédiation cognitive dure entre 4 et 6 mois à raison d’un atelier par semaine.
                                </p>
                                <p>
                                    Le but des ateliers n’est pas de <b>réussir chaque exercice proposé mais bien de remettre en marche le cerveau et de retrouver un confort de travail.</b>
                                </p>
                                <p>
                                    Mettre votre cerveau au travail, pour recréer de nouvelles  connexions neuronales en travaillant de façon intensive. Mais aussi user de stratégies pour progresser. Et comme pour les jeux olympiques : l’essentiel n’est pas de gagner, mais de participer !
                                </p>
                                <p>
                                    Durant ces ateliers ce n’est pas la performance par rapport aux autres qui est mesurée mais bien sa progression personnelle : on ne se compare pas, on échange, on se réconforte mutuellement et surtout on récupère petit à petit ses compétences. 
                                </p>
                                <p>
                                    <oncogite/> <b>vous propose chaque semaine 1h30 d’exercices enchaînés à un rythme soutenu soit en atelier en présentiel soit en visioconférence.</b>
                                </p>
                                <p>
                                    Attention, mémoire, double tache, planification logique, inhibition, capacités visuospatiales, auditives, mémoire de travail… tout est travaillé avec efficacité. Les enchaînements d’exercices sont pensés pour optimiser les résultats et ne surtout pas vriller les fonctions fragilisées.
                                </p>
                                <p>
                                    Très prochainement, ces entraînements   pourront être poursuivis à domicile grâce aux exercices en ligne.
                                </p>
                                <p>
                                    <b>Comment s’inscrire au parcours</b> <oncogite/> :
                                    <br>1- devenez adhérent bénéficiare (20€/an)
                                    <br>2- trouvez l’atelier qui vous correspond le mieux (en présentiel ou en visioconférence)
                                    <br>3- inscrivez-vous en réservant votre première séance. Le professionnel qui anime votre atelier est informé et vous attend à l’heure convenue !
                                    <br>4- Vous choisissez ce qui vous convient le mieux : <b>présentiel ou visio mais vous pouvez aussi combiner les deux</b>
                                </p>
                            </div>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn text color="primary" @click="showHelp = false">Fermer</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <div class="text-center">
                    <v-dialog width="80%" class="justify-center" v-model="ajaWorkshopsIncomingDialog">
                        <v-card >
                            <v-card-title class="justify-center">
                                Bientôt des ateliers "SPECIAL AJA" !
                            </v-card-title>
                            <v-card-actions class="justify-center">
                                <v-btn text color="primary" @click="ajaWorkshopsIncomingDialog = false">Fermer</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <v-row>
                    <v-col cols=12>
                        <v-data-iterator 
                            :items="workshops"
                            :options.sync="options"
                            :server-items-length="total"
                            :loading="loading"
                            hide-default-footer
                        >
                            <template v-slot:header>
                                <v-card elevation="4" color="primary" class="mb-3">
                                    <v-card-text>
                                        <v-row no-gutters align="center" class="headline mb-3">
                                            <v-col cols="12" :md="10">
                                                 <template v-if="$route.name == 'AJAWorkshops'">
                                                    <v-col block class="font-weight-bold white--text">Les ateliers jeunes adultes offerts par nos partenaires</v-col>
                                                </template>
                                                <template v-if="$route.name == 'ListWorkshops'">
                                                    <v-col class="font-weight-bold white--text">Les ateliers offerts par nos partenaires</v-col>
                                                </template>
                                            </v-col>
                                            <!-- <v-btn text color="white" @click="showHelp = true">en savoir plus</v-btn> -->
                                        </v-row>

                                        <v-row dense justify="center" align="center" class="px-2">
                                            <v-col v-if="search.type == 'onsite'">
                                                <v-autocomplete
                                                    v-model="search.zip"
                                                    :items="allDepartments"
                                                    :item-text="d => d.code+' - '+d.nom"
                                                    item-value="code"   
                                                    label="Département"
                                                    :loading="departmentsLoading"
                                                    flat
                                                    solo
                                                    clearable
                                                    hide-details
                                                    no-data-text="Aucun département trouvé"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col v-if="search.type == 'onsite'">
                                                <v-autocomplete
                                                    v-model="search.city"
                                                    :items="allCities"
                                                    item-text="nom"
                                                    item-value="nom"
                                                    label="Ville"
                                                    :loading="citiesLoading"
                                                    flat
                                                    solo
                                                    clearable
                                                    hide-details
                                                    no-data-text="Aucune ville trouvée"
                                                    :filter="citiesFilter"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col>
                                                <v-select
                                                    style="max-height: 15px;"
                                                    v-model="search.day_of_week"
                                                    :items="[
                                                        {text: 'Lundi', value: [1]},
                                                        {text: 'Mardi', value: [2]},
                                                        {text: 'Mercredi', value: [3]},
                                                        {text: 'Jeudi', value: [4]},
                                                        {text: 'Vendredi', value: [5]},
                                                        {text: 'Samedi', value: [6]},
                                                        {text: 'Dimanche', value: [7]},
                                                    ]"
                                                    :disabled="loading"
                                                    :loading="loading"
                                                    label="Jour"
                                                    flat
                                                    solo
                                                    clearable
                                                    hide-details
                                                    dense
                                                ></v-select>
                                                <v-select
                                                    style="max-height: 15px; margin-top: 30px"
                                                    v-model="search.dates"
                                                    :items="allDates"
                                                    label="Période"
                                                    :disabled="loading"
                                                    :loading="loading"
                                                    flat
                                                    solo
                                                    clearable
                                                    hide-details
                                                    dense
                                                ></v-select>
                                                <v-select
                                                    style="max-height: 15px; margin-top: 30px"
                                                    v-model="search.hours"
                                                    :items="allHours"
                                                    label="Horaires"
                                                    flat
                                                    solo
                                                    :disabled="loading"
                                                    :loading="loading"
                                                    clearable
                                                    hide-details
                                                    dense
                                                ></v-select>
                                                <!-- <v-autocomplete
                                                    style="max-height: 15px; margin-top: 30px; margin-bottom: 30px"
                                                    v-model="search.partner_id"
                                                    :items="allPartners"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Centre de soins"
                                                    :loading="partnersLoading || loading"
                                                    :disabled="loading"
                                                    flat
                                                    solo
                                                    clearable
                                                    hide-details
                                                    no-data-text="Aucun partenaire trouvé"
                                                    dense
                                                ></v-autocomplete> -->
                                            </v-col>

                                            <v-col :style="{'background-color': carousselBackgroundColor, 'border-radius': '6px' }" class="py-4 mb-2 px-4 mx-4" cols="12" :md="9">
												<split-carousel
													:height="150"
													:display-amount="columns"
													:arrow-area-width="0"
													:interval="2400"
													:hover-cancel-play="false"
												>
													<split-carousel-item v-for="item in slider" :key="item">
														<v-img justify="center" align="center" :src="item" height="150px" width="150px" contain></v-img>
													</split-carousel-item>
												</split-carousel>
											</v-col>

                                            <v-col cols="12" class="text-center">
												<v-btn-toggle v-model="options.sortDesc" group color="black">
													<v-tooltip bottom>
														<template v-slot:activator="{ on }">
															<v-btn :value="false" v-on="on">
																<v-icon>keyboard_arrow_up</v-icon>
															</v-btn>
														</template>
														<span>Croissant</span>
													</v-tooltip>
													<v-tooltip bottom>
														<template v-slot:activator="{ on }">
															<v-btn :value="true" v-on="on">
																<v-icon>keyboard_arrow_down</v-icon>
															</v-btn>
														</template>
														<span>Décroissant</span>
													</v-tooltip>
												</v-btn-toggle>
											</v-col>
                                           

                                            <!-- <v-col style="min-width: 200px;">
                                               
                                            </v-col>
                                            <v-col style="min-width: 300px;">
                                                <v-row no-gutters align="center">
                                                    <v-col>
                                                        <v-select
                                                            v-model="options.sortBy"
                                                            clearable
                                                            flat
                                                            solo
                                                            hide-details
                                                            prepend-inner-icon="swap_vert"
                                                            label="Trier par"
                                                            :items="sortables"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <v-btn-toggle
                                                            v-model="options.sortDesc"
                                                            group
                                                            color="black"
                                                        >
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn :value="false" v-on="on">
                                                                        <v-icon>keyboard_arrow_up</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Croissant</span>
                                                            </v-tooltip>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn :value="true" v-on="on">
                                                                        <v-icon>keyboard_arrow_down</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Décroissant</span>
                                                            </v-tooltip>
                                                        </v-btn-toggle>
                                                    </v-col>
                                                </v-row>
                                            </v-col> -->
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </template>

                            <template v-slot:default="props">
                                <v-row align="stretch">
                                    <v-col
                                        v-for="(workshop,k) in props.items"
                                        :key="workshop.id"
                                        cols="12"
                                        sm="6"
                                        md="4"
                                    >
                                        <WorkshopCard ref="WorkshopCard" 
                                            :key="workshop.id" 
                                            v-model="workshops[k]"
                                            :link="{ name: 'Workshop', params: {id: workshop.id} }"
                                            :options="options"
                                            :type="workshop.type"
                                        />
                                    </v-col>
                                </v-row>
                            </template>

                            <template v-slot:loading>
                                <v-row align="stretch">
                                    <v-col cols="12">
                                        <v-skeleton-loader type="article, list-item, list-item, list-item" height="400px" width="400px"></v-skeleton-loader>
                                    </v-col>
                                </v-row>
                            </template>

                            <template v-slot:no-data>
                                <v-card elevation="4" class="mt-5">
                                    <v-card-text class="text-center">
                                        <v-icon x-large>sentiment_satisfied</v-icon>
                                        <br>
                                        <span class="title" v-if="$route.name == 'AJAWorkshops'">En période COVID, nos ateliers sont EXCLUSIVEMENT en visio</span>
                                        <span class="title" v-else>Aucun atelier trouvé</span>
                                    </v-card-text>
                                </v-card>
                            </template>
                        </v-data-iterator>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import WorkshopCard from '@/components/workshops/Card'
import { SplitCarousel, SplitCarouselItem } from "vue-split-carousel";
export default {
    components: {
        WorkshopCard,
        SplitCarousel,
        SplitCarouselItem,
    },
    data () {
        return {
            ready: false,
            workshops: [],
            options: {
                sortBy: this.$route.query.sort ? [this.$route.query.sort] : ['subscriptions_count'],
                sortDesc: this.$route.query.direction ? [this.$route.query.direction == 'desc'] : [false],
                mustSort: false,
                multiSort: false,
            },
            search: {
                ...(this.$route.query.search ? JSON.parse(this.$route.query.search) : {}),
                active: true,
            },
            sortables: [
                {value: ['name'], text: 'Nom'},
                {value: ['next_meeting_start'], text: 'Prochaine séance'},
            ],
            total: 0,
            loading: false,
            allDepartments: [],
            allCities: [],
            allDates: [],
            allHours: [],
            departmentsLoading: false,
            citiesLoading: false,
            showHelp: false,
            allPartners: [],
            partnersLoading: false,
            meetingTimeslots: [],
            slider: [],
            logosLoading: false,
            ajaWorkshopsIncomingDialog: false,
            apiUrl: process.env.VUE_APP_API_URL,
            isInit: true,
        }
    },
    mounted () {
        this.refreshPagination()
        this.initAdvancedSearch()
        this.initMeetingSlots()
        this.initLogos()
    },
    computed: {
        // https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
        computedOptions: function() {
            return Object.assign({}, this.options);
        },
        columns() {
            if (this.$vuetify.breakpoint.xl) {
                return 6;
            }
            if (this.$vuetify.breakpoint.lg) {
                return 4;
            }
            if (this.$vuetify.breakpoint.md) {
                return 3;
            }
            return 2;
        },
        carousselBackgroundColor() {
        // Check the value of $vuetify.theme.dark
            return this.$vuetify.theme.dark ? '#1E1E1E' : '#ddd';
        }
    },
    methods: {
        refreshPagination () {
            this.workshops = []
            this.search.is_aja = this.$route.name == "AJAWorkshops" ? true : false

            const queries = {
                sort: this.options.sortBy,
                direction: (this.options.sortDesc?.length ? this.options.sortDesc[0] : false) ? 'desc' : 'asc',
                search: JSON.stringify(this.search),
                with: ['user', 'partner'],
            }

            this.loading = true
            this.$store.dispatch('workshopsRequest', queries)
                .then(result => {
                    this.total = result.meta.total
                    this.workshops = result.data
                    this.ready = true
                    if(this.search.is_aja && this.total == 0) this.ajaWorkshopsIncomingDialog = true
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
                .finally(() => this.loading = false)
        },
        initAdvancedSearch() {
            this.fetchDepartments()
            this.fetchCities()
            this.fetchPartners()
            // init dates items
            this.allDates = [
                {
                    text: 'Cette semaine', 
                    value: [
                        this.$moment().startOf('week').format('YYYY-MM-DD'), 
                        this.$moment().endOf('week').format('YYYY-MM-DD')
                    ]
                },
                {
                    text: 'La semaine prochaine', 
                    value: [
                        this.$moment().add(1, 'week').startOf('week').format('YYYY-MM-DD'),
                        this.$moment().add(1, 'week').endOf('week').format('YYYY-MM-DD'),
                    ]
                },
                {
                    text: 'Ce mois-ci', 
                    value: [
                        this.$moment().startOf('month').format('YYYY-MM-DD'),
                        this.$moment().endOf('month').format('YYYY-MM-DD'),
                    ]
                },
                {
                    text: 'Le mois prochain', 
                    value: [
                        this.$moment().add(1, 'month').startOf('month').format('YYYY-MM-DD'),
                        this.$moment().add(1, 'month').endOf('month').format('YYYY-MM-DD'),
                    ]
                },
            ]
            this.allHours = [
                {
                    text: 'MATIN', 
                    value: ["06:00", "13:01"]
                },
                {
                    text: 'MIDI', 
                    value: ["12:00", "14:01"]
                },
                {
                    text: 'APRES-MIDI', 
                    value: ["13:00", "17:01"]
                },
                {
                    text: 'FIN D\'APRES-MIDI', 
                    value: ["16:30", "20:01"]
                },
            ]
        },
        fetchDepartments(event) {
            this.departmentsLoading = 'secondary'
            this.$axios.get('https://geo.api.gouv.fr/departements')
                .then((resp) => {
                    this.allDepartments = resp.data
                }).finally(() => {
                    this.departmentsLoading = false
                })
        },
        fetchCities(event) {
            this.citiesLoading = 'secondary'
            this.$axios.get('https://geo.api.gouv.fr/communes')
                .then((resp) => {
                    this.allCities = resp.data
                }).finally(() => {
                    this.citiesLoading = false
                })
        },
        fetchPartners() {
            this.partnersLoading = true
            this.$store.dispatch('partnersRequest', {search: JSON.stringify({'type': 'healthcenter'})})
                .then((result) => {
                    this.allPartners = result.data
                })
                .catch((err) => {
                }).finally(() => {
                    this.partnersLoading = false
                })
        },
        citiesFilter(item, queryText, itemText) {
            return item.nom.toLowerCase().indexOf(queryText.toLowerCase()) == 0
        },
        initMeetingSlots() {
            this.meetingTimeslots = []
            for (let i = 8; i < 19; i++)
                for (let j = 0; j < 2; j++)
                    this.meetingTimeslots.push(i.toString().padStart(2, '0') + ":" + (j===0 ? "00" : 30*j) )
        },
        // computeType(workshop){
        //     if(workshop.meetings && workshop.active ){
        //         let meetings = workshop.meetings.filter(meeting => this.$moment(meeting.start) > new this.$moment())
        //         let types = meetings.reduce((r, a) => {
        //             r[a.type] = [...r[a.type] || [], a];
        //             return r;
        //         }, {});

        //         if ('onsite' in types && 'remote' in types) {
        //             return 'onsite'
        //         } 
        //         return 'remote'
        //     }
        //     return workshop.type
        // },
        initLogos() {
			this.$store.dispatch("partnersRequest", { with: ["logo"], search: JSON.stringify({"active": 1}) }).then((result) => {
                // Shuffle the result.data array to get logos displayed randomly
                const shuffledPartners = this.shuffleArray(result.data);
				shuffledPartners.forEach((partner) => {
					if (partner.logo) {
						let url = `${this.apiUrl}/storage/${partner.logo.path}`;
						this.slider.push(url);
					}
				});
			});
		},
        // Shuffle function to randomly reorder an array
        shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
            return array;
        }
    },
    watch: {
        $route(to, from) {
            if(!this.isInit){
                this.refreshPagination()
            }
            this.isInit=false
        },
        // cannot put search in options, options watcher isn't deep enough
        search: {
            handler() {
                if (!this.$route.to && !this.$route.from) {
                    this.$router.replace({
                        query: {
                            search: JSON.stringify(this.search),
                        }
                    }).catch(e => console.log(e))
                }
            },
            deep: true
        },
        // https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
        computedOptions: {
            handler (val, oldVal) {
                if (JSON.stringify(val) != JSON.stringify(oldVal)) {
                    if (! val.sortBy)
                        this.options.sortDesc = false

                    window.scrollTo(0, 0)
                    this.$router.replace({
                        query: {
                            search: JSON.stringify(this.search),
                            sortBy: this.options.sortBy,
                            sortDesc: this.options.sortDesc,
                        }
                    }).catch(e => console.log(e))
                }
            },
            deep: true
        },
    }
}
</script>

<style>
.split-carousel--arrow--left,
.split-carousel--arrow--right {
	visibility: hidden !important;
}
</style>
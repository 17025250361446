const socketURL = process.env.VUE_APP_WEBSOCKET_URL;

const state = {
    connexion: false,
    connected: false,
    uid: null,
}

const getters = {}

const actions = {
    connexion: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            let connexion = new WebSocket(socketURL);

            connexion.onopen = () => {
                commit('setConnexion', connexion);
                resolve();
            };

            connexion.onerror = (error) => {
                reject(error);
            };

            connexion.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.payment === 'validated') {
                    dispatch('profileRequest', {}, { root: true });
                    dispatch('closeConnection');
                }
            };
        });
    },

    join: async ({ commit, dispatch, state }, queries) => {
        if (!state.connexion || state.connexion.readyState !== WebSocket.OPEN) {
            // If WebSocket connection is not established or not open, establish connection first
            try {
                await dispatch('connexion');
            } catch (error) {
                console.error('Failed to establish WebSocket connection:', error);
                return; // Return early if connection could not be established
            }
        }

        // Once connection is established, send join message with user id
        const uid = queries.uid;
        state.connexion.send(JSON.stringify({
            type: 'login',
            uid: uid
        }));
        commit('setConnected', true);
        commit('setUid', uid);
    },

    closeConnection: ({ commit, state }) => {
        if (state.connexion) {
            state.connexion.close();
            commit('setConnexion', null); // Reset connexion state
            commit('setConnected', false); // Reset connected state
            commit('setUid', null); // Reset UID state
        }
    }
}

const mutations = {
    setConnexion(state, connexion) {
        state.connexion = connexion
    },
    setConnected(state, connected) {
        state.connected = connected
    },
    setUid(state, uid) {
        state.uid = uid
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
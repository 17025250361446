<template>
    <div class="register">
        <Navigation />
        <v-main>
            <v-container>
                <v-row justify="center" class="mt-5">
                    <v-col cols="10">
                        <v-card>
                            <CardTitle text="Créer mon compte" />
                            <v-card-text>
                                <v-stepper class="elevation-0" v-model="e1">
                                    <v-stepper-header
                                        :style="{ 'background-color': $vuetify.theme.dark ? '#1E1E1E' : 'white' }"
                                        color="primary">
                                        <v-stepper-step :complete="e1 > 1" step="1">
                                            Orientation
                                        </v-stepper-step>

                                        <v-divider></v-divider>
                                        <v-stepper-step :complete="e1 > 2" step="2">
                                            Formulaire d'inscription
                                        </v-stepper-step>

                                        <v-divider v-if="isLigue || isRetreat"></v-divider>
                                        <v-stepper-step :complete="e1 > 3" v-if="isLigue || isRetreat" step="3">
                                            Code adhérent
                                        </v-stepper-step>

                                        <v-divider v-if="isLigue"></v-divider>
                                        <v-stepper-step v-if="isLigue" step="4">
                                            Questionnaire de profil
                                        </v-stepper-step>
                                    </v-stepper-header>

                                    <v-stepper-items
                                        :style="{ 'background-color': $vuetify.theme.dark ? '#1E1E1E' : 'white' }">
                                        <v-stepper-content step="1">
                                            <div class="text-center">
                                                <h2 class="mb-2">Pour commencer,</h2>
                                                <h2>Choisissez l'une de ces options</h2>
                                            </div>

                                            <v-form class="pl-8 mt-12 mb-12" ref="reasonForm"
                                                v-on:submit.prevent="onSubmit">
                                                <v-radio-group @change="user.registration_process.partner.id = ''"
                                                    :rules="[rules.required]" v-model="user.registration_process.reason">
                                                    <v-radio label="Je suis envoyé.e par la Ligue contre le cancer"
                                                        value="ligue"></v-radio>

                                                    <!-- <v-radio disabled label="Je suis un AJA (moins de 26 ans)"
                                                        value="aja"></v-radio> -->

                                                    <v-radio label="Ma couverture santé prévoyance est" value="healthinsurance"></v-radio>
                                                    <v-radio-group :rules="isHealthinsurance ? [rules.required] : []"
                                                        class="mt-0 ml-12" v-model="user.registration_process.partner.id">
                                                        <v-radio :disabled="!isHealthinsurance"
                                                            v-for="partner in healthInsurancePartners" :key="partner.id"
                                                            :label="partner.name" :value="partner.id"></v-radio>
                                                    </v-radio-group>


                                                    <v-radio label="Je suis retraité.e et ma complémentaire retraite est" value="retreat"></v-radio>
                                                    <v-radio-group :rules="isRetreat ? [rules.required] : []"
                                                        class="mt-0 ml-12" v-model="user.registration_process.partner.id">
                                                        <v-radio :disabled="user.registration_process.reason != 'retreat'"
                                                            v-for="partner in retreatPartners" :key="partner.id"
                                                            :label="partner.name" :value="partner.id"></v-radio>
                                                    </v-radio-group>

                                                    <v-radio label="Mon employeur est" value="employer"></v-radio>
                                                    <v-radio-group class="mt-0 ml-12"
                                                        :rules="isEmployer ? [rules.required] : []"
                                                        v-model="user.registration_process.partner.id">
                                                        <v-radio :disabled="!isEmployer" v-for="partner in employerPartners"
                                                            :key="partner.id" :label="partner.name"
                                                            :value="partner.id"></v-radio>
                                                    </v-radio-group>

                                                    <v-radio class="big-label" label="Autres (majorité des cas de création de compte)" value="other"></v-radio>
                                                </v-radio-group>
                                            </v-form>

                                            <v-btn color="primary" @click="checkReasonFormAndNextStep()">
                                                Continuer
                                            </v-btn>
                                        </v-stepper-content>

                                        <v-stepper-content step="2">

                                            <v-form class="mt-4" ref="form" v-on:submit.prevent="onSubmit">

                                                <v-text-field v-model="user.lastname" label="Nom" outlined
                                                    :rules="[rules.required]"></v-text-field>

                                                <v-text-field v-model="user.firstname" label="Prénom" outlined
                                                    :rules="[rules.required]"></v-text-field>

                                                <date-input v-model="user.birthdate" label="Date de naissance"
                                                    @save="date => user.birthdate = date" required />

                                                <v-text-field v-model="user.street" outlined label="Adresse"
                                                    :rules="[rules.required]" hide-details="auto"
                                                    class="mb-2"></v-text-field>

                                                <v-row>
                                                    <v-col cols="12" :md="4">
                                                        <v-text-field v-model="user.zip" outlined label="Code postal"
                                                            :rules="[rules.required]" @change="fetchCities"
                                                            clearable></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" :md="8">
                                                        <v-autocomplete v-model="user.city" :items="allCities"
                                                            item-text="nom" item-value="nom" label="Ville"
                                                            :loading="citiesLoading" :disabled="citiesLoading" outlined
                                                            :rules="[rules.required]" hide-details="auto"
                                                            no-data-text="Aucune ville trouvée" @change="fetchZip"
                                                            return-object clearable></v-autocomplete>
                                                    </v-col>
                                                </v-row>

                                                <v-text-field v-model="user.phone" outlined label="Téléphone"
                                                    :rules="[rules.required]" @input="formatPhone"></v-text-field>

                                                <v-text-field v-model="user.email" label="E-mail" outlined
                                                    :rules="[rules.required, rules.email]"></v-text-field>

                                                <v-text-field v-model="user.password" label="Mot de passe" outlined
                                                    :rules="[rules.required, rules.minlength]"
                                                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                                    :type="showPassword ? 'text' : 'password'"
                                                    @click:append="showPassword = !showPassword"></v-text-field>

                                                <v-text-field v-model="user.password_confirmation"
                                                    label="Confirmation du mot de passe" outlined
                                                    :rules="[rules.required, rules.minlength]"
                                                    :append-icon="showConfirm ? 'visibility' : 'visibility_off'"
                                                    :type="showConfirm ? 'text' : 'password'"
                                                    @click:append="showConfirm = !showConfirm"></v-text-field>

                                                <v-row justify="center">
                                                    <v-col cols="12" class="text-center">
                                                        <div>
                                                            En adhérent à nos services, vous acceptez les
                                                            <a href="#" @click.prevent="cgDialog = true">Conditions
                                                                générales</a>
                                                            de
                                                            <oncogite />
                                                        </div>
                                                        <div>
                                                            Veuillez également consulter notre notice
                                                            <a href="#" @click.prevent="confidentialityDialiog = true">
                                                                Protection de vos Informations Personnelles
                                                            </a>
                                                        </div>
                                                    </v-col>

                                                    <v-col cols="auto">
                                                        <vue-recaptcha class="mb-8" :sitekey="recaptchaKey"
                                                            :loadRecaptchaScript="true" @verify="canSubmit = true"
                                                            @error="canSubmit = false"
                                                            @expired="canSubmit = false"></vue-recaptcha>
                                                    </v-col>
                                                </v-row>

                                                <v-btn color="primary" type="submit" :disabled="!canSubmit || loading"
                                                    :loading="loading">
                                                    Créer mon compte
                                                </v-btn>
                                                <v-btn text @click="e1 = 1">
                                                    Retour
                                                </v-btn>
                                            </v-form>
                                        </v-stepper-content>

                                        <v-stepper-content v-if="isLigue || isRetreat" step="3">
                                            <v-card flat class="mb-12">
                                                <h3 v-if="isLigue" class="mb-8">Votre
                                                    comité départemental vous offre votre cotisation, il
                                                    sera averti de votre adhésion. </h3>
                                                <h3 v-else-if="isRetreat && user.registration_process.partner.id"
                                                    class="mb-8">
                                                    Adhésion offerte par {{ allPartners.find(p => p.id ==
                                                        user.registration_process.partner.id).name }}
                                                </h3>
                                                <v-form ref="adhCodeForm" v-on:submit.prevent="onSubmit">
                                                    <v-autocomplete v-if="isLigue" :rules="[rules.required]"
                                                        v-model="user.registration_process.partner.id" outlined
                                                        :items="liguePartners" :item-text="formatZipCode" item-value="id"
                                                        label="Merci de selectionner le code postal de votre comité">
                                                    </v-autocomplete>
                                                    <v-text-field v-if="isLigue"
                                                        v-model="user.registration_process.partner.adh_secret_code" outlined
                                                        :rules="[rules.required]"
                                                        label="Merci de saisir le code fourni par votre comité"></v-text-field>

                                                    <v-text-field
                                                        v-else-if="isRetreat && user.registration_process.partner.id"
                                                        v-model="user.registration_process.partner.adh_secret_code" outlined
                                                        :rules="[rules.required]" :label="'Merci de saisir le code fourni par ' + allPartners.find(p => p.id ==
                                                            user.registration_process.partner.id).name"></v-text-field>

                                                    <p class="mb-8" v-if="isLigue">Le code secret n'est pas conforme ou ne m'a pas été communiqué, 
                                                        <a target="_blank" href="https://www.ligue-cancer.net/la-ligue-pres-de-chez-vous-comite">je contacte mon comité départemental</a>
                                                    </p>
                                                </v-form>
                                            </v-card>

                                            <v-btn color="primary" @click="checkAdhSecretCodeFormAndNextStep">
                                                Valider
                                            </v-btn>
                                            <v-btn text @click="openChangeReasonDialog()">
                                                Modifier mon orientation
                                            </v-btn>
                                        </v-stepper-content>

                                        <v-stepper-content v-if="isLigue" step="4">
                                            <v-card flat class="mb-12">
                                                <Survey v-model="survey" :from_registration="true"
                                                    @saved="onSaveSurveyRedirect" />
                                            </v-card>
                                        </v-stepper-content>
                                    </v-stepper-items>
                                </v-stepper>

                                <v-btn block text small color="secondary" :to="{ name: 'Home' }"
                                    class="mt-4">Annuler</v-btn>

                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- CG DIALOG -->
                <v-dialog v-model="cgDialog" max-width="80%">
                    <CGU closable @close="cgDialog = false" />
                </v-dialog>

                <!-- Confidentiality DIALOG -->
                <v-dialog v-model="confidentialityDialiog" max-width="80%">
                    <Confidentiality closable @close="confidentialityDialiog = false" />
                </v-dialog>

                <v-dialog persistent v-model="changeReasonDialog" max-width="800">
                <v-card class="text-center pt-6">
                    <v-card-text >
                        <div>
                            <h2>Modifier le motif de ma visite</h2>
                        </div>
                        <SubscriptionReasonForm v-if="changeReasonDialog" :registration_process="user.registration_process" @cancelUpdateReason="changeReasonDialog = false"
                            @savedForm="handleSaveReasonForm" />
                    </v-card-text>
                </v-card>
            </v-dialog>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import CGU from '@/components/CGU';
import Confidentiality from '@/components/Confidentiality';
import Survey from '@/components/surveys/Form'
import SubscriptionReasonForm from '@/components/SubscriptionReasonForm'
export default {
    components: {
        VueRecaptcha,
        CGU,
        Confidentiality,
        Survey,
        SubscriptionReasonForm,
    },
    data() {
        return {
            user: {
                "registration_process": {
                    "reason": "",
                    "partner": { "id": "", "adh_secret_code": "" }
                },
            },
            // TEST USER
            // user: {
            //     "lastname": "dd",
            //     "firstname": "dd",
            //     "birthdate": "2023-01-01",
            //     "street": "dd",
            //     "zip": "20200",
            //     "city": "Bastia",
            //     "phone": "09 00 00 00 00",
            //     "email": (Math.random() + 1).toString(36).substring(7) + "@fez.fr",
            //     "password": "00000000",
            //     "password_confirmation": "00000000",
            //     "registration_process": {
            //         "reason": "ligue",
            //         "partner": {
            //             "id": "",
            //             "adh_secret_code": "",
            //         }
            //     },
            // },
            showPassword: false,
            showConfirm: false,
            loading: false,
            rules: {
                required: value => !!value || 'Champ requis',
                minlength: value => !value || (value && value.length >= 8) || '8 caractères min.',
                email: value => /.+@.+\..+/.test(value) || 'Format invalide',
            },
            canSubmit: false,
            cgDialog: false,
            confidentialityDialiog: false,
            allCities: [],
            citiesLoading: false,
            recaptchaKey: process.env.VUE_APP_RECAPTCHA_KEY,
            e1: 1,
            adh_code: "",
            allPartners: [],
            survey: {},
            changeReasonDialog: false,
        }
    },
    mounted() {
        this.fetchCities()
        this.getPartners()
    },
    computed: {
        isLigue() {
            return this.user.registration_process.reason == "ligue"
        },
        isHealthinsurance() {
            return this.user.registration_process.reason == "healthinsurance"
        },
        isEmployer() {
            return this.user.registration_process.reason == "employer"
        },
        isRetreat() {
            return this.user.registration_process.reason == "retreat"
        },
        liguePartners() {
            return this.allPartners.filter(p => p.support_type == 'ligue')
        },
        healthInsurancePartners() {
            return this.allPartners.filter(p => p.support_type == 'healthinsurance')
        },
        employerPartners() {
            return this.allPartners.filter(p => p.support_type == 'employer')
        },
        retreatPartners() {
            return this.allPartners.filter(p => p.support_type == 'retreat')
        },
    },
    methods: {
        formatZipCode(item) {
            if (!item || !item.zip) {
                return ''
            }
            //Spécific cases
            if (item.zip.substring(0, 2) == "97" || item.zip.substring(0, 2) == "98" ) {
                return item ? item?.zip?.slice(0, 3) : '';
            } else {
                return item ? item?.zip?.slice(0, 2) : '';
            }
        },
        formatPhone() {
            this.user.phone = this.user.phone.replace(/\s/g, '').match(/.{1,2}/g).join(' ')
        },
        fetchCities() {
            this.citiesLoading = true
            let url = 'https://geo.api.gouv.fr/communes'
            if (this.user.zip && this.user.zip.length == 5)
                url += '?codePostal=' + this.user.zip
            this.$axios.get(url)
                .then((resp) => {
                    this.allCities = resp.data
                    if (this.allCities.length == 1) {
                        this.user.city = this.allCities[0].nom
                        this.user.zip = this.allCities[0].codesPostaux[0]
                    }
                }).finally(() => {
                    this.citiesLoading = false
                })
        },
        fetchZip() {
            if (this.user.city && this.user.city.codesPostaux) {
                this.user.zip = this.user.city.codesPostaux[0]
                this.user.city = this.user.city.nom
            }
        },
        getPartners() {
            this.$store.dispatch('partnersRequest', {})
                .then((result) => this.allPartners = result.data)
                .catch(() => this.$store.dispatch('setErrors', 'Error while fetching partners'))
        },

        onSubmit: function () {
            if (this.$refs.form.validate() && this.canSubmit) {
                this.loading = true
                this.$store.dispatch('registerRequest', this.user)
                    .then(() => {
                        // auth request
                        const { email, password } = this.user;
                        this.$store.dispatch('authRequest', { email, password })
                            .then(() => {
                                this.loading = false
                                this.redirectUser()
                            })
                            .catch((err) => {
                                this.loading = false
                            })
                    })
                    .catch((err) => {
                        this.loading = false
                        if (err.response.status != 403)
                            this.$store.dispatch('setErrors', err.response.data)
                    })
            }
        },

        async redirectUser() {
            if (this.isLigue || this.isRetreat) {
                this.e1 = 3
            } else {
                this.$router.push({ name: 'Welcome' })
            }
        },

        checkReasonFormAndNextStep() {
            if (this.$refs.reasonForm.validate()) {
                if(!this.user.id) {
                    this.e1 = 2
                } else {
                    this.redirectUser()
                }
            }
        },

        async checkAdhSecretCodeFormAndNextStep() {
            if (this.$refs.adhCodeForm.validate()) {
                if (this.isLigue || this.isRetreat) {
                    this.checkAdhSecretCode()
                } else {
                    this.$router.push({ name: 'Welcome' })
                }
            }
        },

        async checkAdhSecretCode() {
            this.$store.dispatch('partnerCheckAdhSecretCode', this.user.registration_process.partner)
                .then(() => {
                    this.$store.dispatch('updateValidCodeRegistered', this.user.registration_process.partner);
                    if (this.isLigue) {
                        this.fetchSurvey()
                        this.e1 = 4
                    } 
                    else if (this.isRetreat) {
                        this.$router.push({ name: 'Payment', query: { subscription: 1 } });
                    }
                    else {
                        this.$router.push({ name: 'Welcome' })
                    }
                })
                .catch((err) => {
                    let errorMsg = `${err.response.data.error}, renseignez-vous auprès de ${this.allPartners.find(p => p.id == this.user.registration_process.partner.id).name}`
                    this.$store.dispatch('setErrors', errorMsg)
                })
        },

        async fetchSurvey() {
            this.$store.dispatch('surveyRequest', { id: 2, with: ['survey_questions'] })
                .then(result => {
                    this.survey = result.data
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        onSaveSurveyRedirect() {
            this.$store.dispatch('updateQprRegistered')
            this.$router.push({ name: 'Payment', query: { subscription: 1 } });
        },
        openChangeReasonDialog() {
            this.changeReasonDialog = true
        },
        handleSaveReasonForm(registrationProcess) {
            this.user.registration_process = registrationProcess
            this.changeReasonDialog = false
            this.redirectUser()
        }
    }
}
</script>

<style>
  .big-label .v-label {
    font-size: 24px; /* Adjust the font size as needed */
  }
</style>

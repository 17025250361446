<template>
    <div class="login">
        <Navigation/>
        <v-main>
            <v-container>
                <v-row justify="center" class="my-5">
                    <v-col cols="12" :md="8">
                        <v-card>
                            <CardTitle text="Identifiez-vous"/>
                            <v-card-text>
                                <v-form
                                    ref="form"
                                    v-on:submit.prevent="login"
                                >
                                    <v-text-field
                                        v-model="email"
                                        label="E-mail"
                                        :rules="[rules.required, rules.email]"
                                        outlined
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="password"
                                        label="Mot de passe"
                                        :rules="[rules.required]"
                                        :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                        :type="showPassword ? 'text' : 'password'"
                                        @click:append="showPassword = !showPassword"
                                        outlined
                                    ></v-text-field>

                                    <div class="d-flex justify-center">
                                        <v-checkbox label="Se souvenir de moi" dense class="mt-0"></v-checkbox>
                                    </div>

                                    <v-row justify="center">
                                        <v-col cols="12" :sm="6" class="text-center">
                                            <v-btn
                                                block
                                                color="primary"
                                                type="submit"
                                                :disabled="loading" 
                                                :loading="loading"
                                            >Se connecter</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                <v-row>
                                    <v-col>
                                        <v-btn
                                            block
                                            text
                                            small
                                            color="secondary"
                                            :to="{ name: 'register' }"
                                        >Créer un compte</v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-btn
                                            block
                                            text
                                            small
                                            color="secondary"
                                            :to="{ name: 'password-reset' }"
                                        >Mot de passe oublié ?</v-btn>
                                    </v-col>
                                </v-row>

                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <my-footer/>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                email: '',
                password: '',
                showPassword: false,
                loading: false,
                rules: {
                    required: value => !!value || 'Champ requis',
                    email: value => /.+@.+\..+/.test(value) || 'Format invalide',
                },
            }
        },
        methods: {
            login: function () {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    const { email, password } = this;
                    this.$store.dispatch('authRequest', { email, password })
                        .then(() => {
                            // if (this.$store.getters.getProfile.status == 'invalid')
                            //     this.$router.push({name: 'Payment', query: {subscription: 1}})
                            // else {
                            //     let redirect = this.$store.getters.getToRoute()
                            //     if (redirect && ['Unauthorized', 'login'].indexOf(redirect.name) < 0)
                            //         this.$router.push(redirect)
                            //     else
                            //         this.$router.push({name: 'Home'})
                            // }
                            this.$router.push({name: 'Home'})   
                        })
                        .catch((err) => {
                            this.loading = false;
                        })
                        .finally(() => this.loading = false)
                }
            }
        }
    }
</script>

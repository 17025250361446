<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form" class="mt-3">
        <v-row v-if="cannotEdit">
             <v-col cols="12">
                <v-radio-group v-model="meeting.type" row class="ml-4">
                    <span class="mr-6"> Mode : </span>
                    <v-radio disabled :active-class="workshop.type =='remote'" label="Visio" value="remote" ></v-radio>
                    <v-radio disabled label="Présentiel" value="onsite" ></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12" :md="4">
                <v-text-field
                    :value="formattedMeetingDate"
                    label="Date"
                    disabled
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" :md="4">
                <v-text-field
                    v-model="startTime"
                    label="Heure de début"
                    disabled
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" :md="4">
                <v-text-field
                    v-model="endTime"
                    label="Heure de fin"
                    disabled
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
             <v-radio-group v-model="meeting.type" row class="ml-4">
                 <span class="mr-6"> Mode : </span>
                <v-radio label="Visio" value="remote" ></v-radio>
                <v-radio :disabled="workshop.type =='remote'" label="Présentiel" value="onsite" ></v-radio>
            </v-radio-group>
            </v-col>
            <v-col cols="12" :md="4">
                <v-menu
                    v-model="menu_meeting_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="formattedMeetingDate"
                            label="Date"
                            readonly
                            v-on="on"
                            outlined
                            hide-details
                            :rules="[rules.required]"
                        ></v-text-field>
                    </template>
                    <v-date-picker 
                        v-model="date" 
                        @input="menu_meeting_date = false" 
                        locale="fr-FR"
                        :min="$moment().add(1, 'd').format('YYYY-MM-DD')"
                        color="primary"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" :md="4">
                <v-menu
                    ref="menuStart"
                    v-model="menu_meeting_start"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="startTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="startTime"
                            label="Heure de début"
                            readonly
                            v-on="on"
                            outlined
                            hide-details
                            :rules="[rules.required]"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu_meeting_start"
                        v-model="startTime"
                        full-width
                        @click:minute="$refs.menuStart.save(startTime)"
                        format="24hr"
                        :max="endTime"
                        color="primary"
                    ></v-time-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" :md="4">
                <v-menu
                    ref="menuEnd"
                    v-model="menu_meeting_end"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="endTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="endTime"
                            label="Heure de fin"
                            readonly
                            v-on="on"
                            outlined
                            hide-details
                            :rules="[rules.required]"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu_meeting_end"
                        v-model="endTime"
                        full-width
                        @click:minute="$refs.menuEnd.save(endTime)"
                        format="24hr"
                        :min="startTime"
                        color="primary"
                    ></v-time-picker>
                </v-menu>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-text-field
                    v-model="meeting.sequence"
                    outlined
                    label="Numéro de séquence"
                    :rules="[rules.min1, rules.max25]"
                ></v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                    v-model="meeting.places"
                    outlined
                    label="Nombre de place"
                    :rules="[rules.required, rules.min1]"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-textarea
                    v-if="meeting.type == 'remote'"
                    v-model="meeting.link"
                    outlined
                    auto-grow
                    label="Lien"
                    hide-details
                ></v-textarea>
                <v-textarea
                    v-if="meeting.type == 'onsite'"
                    v-model="meeting.address"
                    outlined
                    auto-grow
                    label="Adresse"
                    hide-details
                ></v-textarea>
            </v-col>
        </v-row>

        <v-row class="mt-6">
            <v-col>
                <v-autocomplete
                    v-model="meeting.subscribers"
                    :items="proposedSubscribers"
                    @change="checkUsers('subscribers')"
                    item-text="complete_name"
                    item-value="id"
                    label="Ajouter / supprimer des participants"
                    multiple
                    chips
                    deletable-chips
                    hint="Veuillez sélectionner les participants à cette séance"
                    :search-input.sync="subscribersQuery"
                    :counter="meeting.places"
                    :error-messages="errorPlaces"
                    :loading="loadingUser"
                    no-filter
                    return-object
                ></v-autocomplete>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-autocomplete
                    v-model="meeting.queued"
                    :items="proposedQueued"
                    @change="checkUsers('queued')"
                    item-text="complete_name"
                    item-value="id"
                    label="Liste d'attente"
                    multiple
                    chips
                    deletable-chips
                    hint="Veuillez sélectionner les bénéficiaires en attente"
                    :search-input.sync="queuedQuery"
                    :loading="loadingUser"
                    no-filter
                    return-object
                ></v-autocomplete>
            </v-col>
        </v-row>

        <v-row justify="center" class="mt-5">
            <v-btn @click.stop="cancel" text color="secondary">Annuler</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">Sauvegarder</v-btn>
        </v-row>
    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
        workshop: Object,
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || 'Champ requis',
                min1: value => !value || (value && value >= 1) || 'supérieur ou égale à 1',
                max25: value => !value || (value && value <= 25) || 'inférieur ou égale à 25',
            },
            menu_meeting_date: false,
            menu_meeting_start: false,
            menu_meeting_end: false,
            meeting: Object.assign({}, this.value),
            date: '',
            startTime: '',
            endTime: '',
            proposedSubscribers: [],
            proposedQueued: [],
            loadingUser: false,
            cannotEdit: false,
            errorPlaces: [],
            subscribersQuery: '',
            queuedQuery: '',
            search: {},
        }
    },
    computed: {
        formattedMeetingDate () {
            return this.date ? this.$moment(this.date).format('DD/MM/YYYY') : ''
        },
    },
    watch: {
        meeting: {
            handler() {
                if (this.meeting.id) {
                    this.date = this.$moment(this.meeting.start).format('YYYY-MM-DD')
                    this.startTime = this.$moment(this.meeting.start).format('HH:mm')
                    this.endTime = this.$moment(this.meeting.end).format('HH:mm')

                    this.errorPlaces = []
                    if (this.meeting.subscribers.length > this.meeting.places)
                        this.errorPlaces = ['Le nombre de participants dépasse le nombre de place limite']
                }
            },
            immediate: true,
            deep: true,
        },
        subscribersQuery: {
            async handler(){
                this.proposedSubscribers = this.meeting.subscribers
                // fetch only if 1 character min
                if (this.subscribersQuery && this.subscribersQuery.length > 0) {
                    this.search.name = this.subscribersQuery
                    const fetchUsersResult = await this.fetchUsers()
                    this.proposedSubscribers = this.meeting.subscribers.concat(fetchUsersResult)
                }
            }
        },
        queuedQuery: {
            async handler(){
                this.proposedQueued = this.meeting.queued
                // fetch only if 1 character min
                if (this.queuedQuery && this.queuedQuery.length > 0) {
                    this.search.name = this.queuedQuery
                    const fetchUsersResult = await this.fetchUsers()
                    this.proposedQueued = this.meeting.queued.concat(fetchUsersResult)
                }
            }
        }
    },
    mounted() {
        if(!this.meeting.id && this.workshop.start && this.workshop.end) {
            this.startTime =  this.$moment(this.workshop.start, "HH:mm:ss").format('HH:mm')
            this.endTime = this.$moment( this.workshop.end, "HH:mm:ss").format('HH:mm')
        }
        // this.fetchUsers()
        this.proposedSubscribers = this.meeting.subscribers
        this.proposedQueued = this.meeting.queued

        // if editing && already subscribers, cannotEdit = true
        this.cannotEdit = this.meeting.id && this.meeting.subscribers.length > 0
    },
    methods: {
        onSubmit () {
            if(this.meeting.type == "remote" && !this.meeting.link) {
                this.$store.commit('setSnack', {'type': 'error', 'msg': 'Merci de renseigner le lien zoom'})
                return
            }
            if(this.meeting.type == "onsite" && !this.meeting.address) {
                this.$store.commit('setSnack', {'type': 'error', 'msg': 'Merci de renseigner l\'adresse'})
                return
            }
            if (this.$refs.form.validate() && //empeche l'envoie de la requette si pas assez de places
            ((this.meeting.subscribers.length < this.meeting.places) || (this.meeting.subscribers.length == this.meeting.places) )) {
                this.loading = true

                // format start/end
                this.meeting.start = this.date + ' ' + this.startTime + ':00'
                this.meeting.end = this.date + ' ' + this.endTime + ':00'

                if (! this.meeting.workshop_id)
                    this.meeting.workshop_id = this.workshop.id

                let request = 'meetingCreateRequest'
                let queries = this.meeting

                if (this.meeting.id) {
                    request = 'meetingEditRequest'
                    queries = {id: this.meeting.id, datas: this.meeting}
                    // format subscribers
                    queries.datas.subscribers = queries.datas.subscribers.map((sub) => sub.id)
                    queries.datas.queued = queries.datas.queued.map((sub) => sub.id)
                }

                this.$store.dispatch(request, queries)
                    .then((meeting) => {
                        this.loading = false
                        this.$store.commit('setSnack', {'type': 'success', 'msg': 'Séance enregistrée !'})
                        this.$emit('savedForm', meeting)
                    })
                    .catch((err) => {
                        this.loading = false
                        if (err.response.status != 403)
                            this.$store.dispatch('setErrors', err.response.data)
                    })
            }
        },
        cancel() {
            this.$emit('canceledForm');
        },
        fetchUsers() {
            this.loadingUser = true
            this.search.role = 'user'
            let queries = {
                search: JSON.stringify({...this.search, is_archived: false}),
                per_page: 5
            }
            let users = []
            return this.$store.dispatch('usersRequest', queries)
                .then((result) => {
                    users = result.data
                    return users
                }).catch((err) => {

                })
                .finally(() => {
                    this.search.name = null
                    this.loadingUser = false
                    return users
                })
        },
        checkUsers(field) {
            if (field == 'queued'){
                this.meeting.subscribers = this.meeting.subscribers.filter((u) => {
                    return this.meeting.queued.filter((q) => q.id == u.id).length == 0
                })
            }
            if (field == 'subscribers'){
                this.meeting.queued = this.meeting.queued.filter((u) => {
                    return this.meeting.subscribers.filter((sub) => sub.id == u.id).length == 0
                })
            }
        }
    }
}
</script>

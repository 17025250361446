<template>
    <div class="meetings">
        <Navigation/>
        <v-main>
            <v-container>
                <v-row v-if="$acl.not.check('isUserPremium') && $acl.not.check('isProPremium')">
                    <v-col cols="12">
                        <NotPremiumCard/>
                    </v-col>
                </v-row>

                <v-row v-else>
                    <v-col cols="12">
                        <v-card elevation="4" color="primary">
                            <v-card-text>
                                <v-row no-gutters align="center" class="headline mb-3">
                                    <v-col cols="auto"><v-icon x-large class="white--text mr-5">alarm</v-icon></v-col>
                                    <v-col class="font-weight-bold white--text text-no-wrap">
                                        <template v-if="user">
                                            Liste des séances de {{ user.complete_name }}
                                        </template>
                                        <template v-else>
                                            Liste de mes séances
                                        </template>
                                    </v-col>
                                </v-row>

                                <v-row dense align="center">
                                    <v-col v-if="user" cols="12" :sm="6" :md="3">
                                        <v-autocomplete
                                            v-model="search.partner_id"
                                            :items="allPartner"
                                            item-text="name"
                                            item-value="id"
                                            label="Partenaire"
                                            :loading="partnersLoading"
                                            flat
                                            solo
                                            clearable
                                            hide-details
                                            no-data-text="Aucun partenaire trouvé"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" :sm="6" :md="3">
                                        <v-select
                                            v-model="search.dates"
                                            :items="allDates"
                                            label="Séances"
                                            flat
                                            solo
                                            clearable
                                            hide-details
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" :sm="6" :md="3">
                                        <v-select
                                            v-model="search.type"
                                            :items="types"
                                            label="Type de séance"
                                            flat
                                            solo
                                            clearable
                                            hide-details
                                        ></v-select>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="12" md="auto">
                                        <v-row no-gutters align="center">
                                            <v-col>
                                                <v-select
                                                    v-model="options.sortBy"
                                                    clearable
                                                    flat
                                                    solo
                                                    hide-details
                                                    prepend-inner-icon="swap_vert"
                                                    label="Trier par"
                                                    :items="sortables"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn-toggle
                                                    v-model="options.sortDesc"
                                                    group
                                                    color="black"
                                                >
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn :value="false" v-on="on">
                                                                <v-icon>keyboard_arrow_up</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Croissant</span>
                                                    </v-tooltip>
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn :value="true" v-on="on">
                                                                <v-icon>keyboard_arrow_down</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Décroissant</span>
                                                    </v-tooltip>
                                                </v-btn-toggle>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12">
                        <v-card elevation="0" color="primary">
                            <v-card-text class="body-1 font-weight-bold">
                                Le n° de la séquence n’a pas d’importance, vous pouvez commencer n’importe quand.
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12">
                        <v-data-table 
                            :items="meetings"
                            :headers="[
                                {
                                    text: 'Atelier',
                                    align: 'left',
                                    value: 'workshop.name',
                                    sortable: false,
                                },
                                {
                                    text: 'Date',
                                    align: 'center',
                                    value: 'start',
                                    sortable: false,
                                },
                                {
                                    text: 'Type',
                                    align: 'center',
                                    value: 'type',
                                    sortable: false,
                                },
                                {
                                    text: 'Horaires',
                                    align: 'center',
                                    value: 'timeslot',
                                    sortable: false,
                                },
                                {
                                    text: 'Nombre de places restantes',
                                    align: 'center',
                                    value: 'places_diff',
                                    sortable: false,
                                },
                                {
                                    text: 'Séquence',
                                    value: 'sequence', 
                                    align: 'center', 
                                    sortable: false,
                                },
                                {
                                    text: '',
                                    value: 'downloads',
                                    align: 'center',
                                    sortable: false,
                                },
                                {
                                    text: 'État',
                                    value: 'actions',
                                    align: 'center',
                                    sortable: false,
                                },
                            ]"
                            :options.sync="options"
                            :server-items-length="total"
                            :loading="loading"
                            hide-default-footer  
                            :item-class="(item) => {return {'grey--text': $moment().diff(item.start) > 0}}"
                        >
                            <template v-slot:item.workshop.name="{ item }">
                                <router-link :to="{name: 'Workshop', params: {id: item.workshop.id}}">
                                    <span>{{ item.workshop.name }}</span>
                                </router-link>
                            </template>

                            <template v-slot:item.start="{ item }">
                                <span class="text-no-wrap">{{ $moment(item.start).format('dddd DD/MM/YYYY') }}</span>
                            </template>

                            <template v-slot:item.type="{ item }">
                                <v-btn dark  v-if="item.type == 'remote'"  class="px-11" style="border-radius: 0px; pointer-events: none;">
                                    VISIO
                                </v-btn>

                                <v-btn color="#D3D3D3" dark v-if="item.type == 'onsite'" style="color: black; border-radius: 0px; pointer-events: none;">
                                    PRESENTIEL
                                </v-btn>
                            </template>

                            <template v-slot:item.timeslot="{ item }">
                                <span class="text-no-wrap">{{ $moment(item.start).format('HH[h]mm') }} - {{ $moment(item.end).format('HH[h]mm') }}</span>
                            </template>

                            <template v-slot:item.places_diff="{ item }">
                                {{ item.places - item.validated_subscribers_count }} <small>/ {{ item.places }}</small>
                            </template>

                            <template v-slot:item.downloads="{ item }">
                                <template v-if="$moment().diff($moment(item.end).add(10, 'minutes')) < 0  && !user">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-on="on"
                                                v-bind="attrs"
                                                outlined
                                                color="primary"
                                                small
                                                class="mx-5"
                                                depressed
                                            >
                                                <v-icon>get_app</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="downloadSupport('user', item.sequence)">
                                                <v-list-item-title>Télécharger le livret d’activités de la séance</v-list-item-title>
                                            </v-list-item>
                                             <v-list-item v-if="item.type == 'onsite' && item.address">
                                                <v-list-item-title>Adresse : {{ item.address }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-if="item.type == 'remote' && item.link">
                                                <v-list-item-title v-html="urlify(item.link)"></v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <template v-if="$moment().diff(item.end) <= 0">
                                    <v-tooltip bottom v-if="isValidatedSubscriber(item) && $moment().diff(item.start) <= 0">
                                        <template v-slot:activator="{ on }">
                                            <ConfirmButton 
                                                :on="on"
                                                buttonText="Inscrit"
                                                headline="Vous êtes sur le point d'annuler votre participation à cette séance"
                                                color="green"
                                                small
                                                @confirmed="unsubscribeMeeting(item.id)"
                                                class="mx-5"
                                            />
                                        </template>
                                        <span>Cliquer pour annuler votre participation</span>
                                    </v-tooltip>
                                    <v-tooltip left v-if="isWaitingSubscriber(item)">
                                        <template v-slot:activator="{ on }">
                                            <ConfirmButton 
                                                :on="on"
                                                buttonText="Sur liste d'attente"
                                                headline="Vous êtes sur le point de supprimer votre alerte sur cette séance"
                                                color="red"
                                                small
                                                @confirmed="unsubscribeMeeting(item.id)"
                                                class="mx-5"
                                            />
                                        </template>
                                        <span>Cliquer pour vous désabonner</span>
                                    </v-tooltip>
                                </template>
                            </template>

                            <template v-slot:loading>
                                <v-row align="stretch">
                                    <v-col>
                                        <v-skeleton-loader type="table-tbody" height="400px" width="100%"></v-skeleton-loader>
                                    </v-col>
                                </v-row>
                            </template>

                            <template v-slot:no-data>
                                <v-icon x-large>sentiment_satisfied</v-icon>
                                <br>
                                <span class="title">Aucune séance trouvée</span>
                            </template>

                        </v-data-table>
                    </v-col>
                    <v-col cols="12" v-if="!loading" class="mt-5" align="center" justify="center">
                        <v-btn
                            fab
                            color="primary"
                            class="mr-1"
                            @click="prevPage"
                            :disabled="options.page <= 1"
                        >
                            <v-icon x-large>keyboard_arrow_left</v-icon>
                        </v-btn>
                        <span class="mx-4 grey--text">
                            Page {{ options.page }} sur {{ lastPage }}
                        </span>
                        <v-btn
                            fab
                            color="primary"
                            class="ml-1"
                            @click="nextPage"
                            :disabled="options.page == lastPage"
                        >
                            <v-icon x-large>keyboard_arrow_right</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
export default {
    props: {
        user_id: { type: [String, Number], required: false },
    },
    data () {
        return {
            ready: false,
            meetings: [],
            options: {
                page: parseInt(this.$route.query.page) || 1,
                itemsPerPage: 15,
                sortBy: this.$route.query.sortBy || 'start',
                sortDesc: this.$route.query.sortDesc ? this.$route.query.sortDesc == 'true' : true,
                groupBy: [],
                groupDesc: [],
                mustSort: false,
                multiSort: false,
            },
            search: this.$route.query.search ? JSON.parse(this.$route.query.search) : {},
            sortables: [
                {value: 'start', text: 'Date'},
                {value: 'sequence', text: 'Séquence'},
            ],
            total: 0,
            isMobile: false,
            loading: false,
            allDepartments: [],
            allCities: [],
            allPartner: [],
            types: [],
            allDates: [],
            departmentsLoading: false,
            citiesLoading: false,
            partnersLoading: false,
            user: null,
        }
    },
    mounted () {
        if (this.user_id) this.fetchUser()
        this.refreshPagination()
        this.initAdvancedSearch()
    },
    computed: {
        lastPage() {
            return Math.ceil(this.total / this.options.itemsPerPage)
        },
        // https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
        computedOptions: function() {
            return Object.assign({}, this.options);
        },
        isThePro() {
            return this.$acl.check('isProPremium')
        },
    },
    methods: {
        prevPage() {
            if (this.options.page - 1 >= 1) this.options.page -= 1
        },
        nextPage() {
            if (this.options.page + 1 <= this.lastPage) this.options.page += 1
        },
        refreshPagination () {
            const queries = {
                page: this.options.page,
                per_page: this.options.itemsPerPage,
                sort: this.options.sortBy,
                direction: this.options.sortDesc ? 'desc' : 'asc',
                search: JSON.stringify({...this.search, user_id: this.user_id || this.$store.getters.getProfile.id}),
                with: ['workshop.partner'],
            }
            this.isThePro ? queries.with.push('subscribers') : queries.with.push('meeting_users')

            this.loading = true
            this.meetings = []
            this.$store.dispatch('meetingsRequest', queries)
                .then(result => {
                    if (! result.data.length && this.options.page > 1)
                        this.options.page--

                    if (! result.data.length)
                        this.options.page = 0

                    this.total = result.meta.total
                    this.meetings = result.data
                    this.loading = false
                    this.ready = true;
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        initAdvancedSearch() {
            this.fetchPartner()
            // init dates items
            this.types = [
                {
                    text: 'Visio', 
                    value: 'remote'
                },
                {
                    text: 'Présentiel', 
                    value: 'onsite'
                },
            ],
            this.allDates = [
                {
                    text: 'Cette semaine', 
                    value: [
                        this.$moment().startOf('week').format('YYYY-MM-DD'), 
                        this.$moment().endOf('week').format('YYYY-MM-DD')
                    ]
                },
                {
                    text: 'La semaine prochaine', 
                    value: [
                        this.$moment().add(1, 'week').startOf('week').format('YYYY-MM-DD'),
                        this.$moment().add(1, 'week').endOf('week').format('YYYY-MM-DD'),
                    ]
                },
                {
                    text: 'Ce mois-ci', 
                    value: [
                        this.$moment().startOf('month').format('YYYY-MM-DD'),
                        this.$moment().endOf('month').format('YYYY-MM-DD'),
                    ]
                },
                {
                    text: 'Le mois prochain', 
                    value: [
                        this.$moment().add(1, 'month').startOf('month').format('YYYY-MM-DD'),
                        this.$moment().add(1, 'month').endOf('month').format('YYYY-MM-DD'),
                    ]
                },
            ]
        },
        fetchPartner(event) {
            this.partnersLoading = 'secondary'
            this.$store.dispatch('partnersRequest', {})
                .then((result) => {
                    this.allPartner = result.data
                })
                .catch((err) => {
                }).finally(() => {
                    this.partnersLoading = false
                })
        },
        fetchUser() {
            this.$store.dispatch('userRequest', {id: this.user_id})
                .then(result => {
                    this.user = result.data
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        unsubscribeMeeting(meeting_id) {
            return this.$store.dispatch('meetingUnsubscribeRequest', {meeting_id: meeting_id, user_id: this.$store.getters.getProfile.id}).then((meeting) => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': 'Participation annulée !'});
                this.refreshPagination()
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.dispatch('setErrors', err.response.data)
            });
        },
        isValidatedSubscriber(meeting) {
            if(meeting.subscribers) {
                return meeting.subscribers.findIndex(s => s.id == this.$store.getters.getProfile.id && s.pivot.status == 'validated') > -1
            } else if (meeting.meeting_users) {
                return meeting.meeting_users.findIndex(meeting_user => meeting_user.user_id == this.$store.getters.getProfile.id && meeting_user.status == 'validated') > -1
            }
        },
        isWaitingSubscriber(meeting) {
            if(meeting.subscribers) {
                return meeting.subscribers.findIndex(s => s.id == this.$store.getters.getProfile.id && s.pivot.status == 'waiting') > -1
            } else if (meeting.meeting_users) {
                return meeting.meeting_users.findIndex(meeting_user => meeting_user.user_id == this.$store.getters.getProfile.id && meeting_user.status == 'waiting') > -1
            }
        },
        downloadSupport(type, sequence) {
            const queries = {
                per_page: -1,
                search: JSON.stringify({'sequence': sequence}),
                with: ['user_attachment'],
            }
            return this.$store.dispatch('supportsRequest', queries).then((data) => {
                let results = data.data
                if (results.length)
                    this.downloadAttachment(results[0][type+'_attachment'])
            })
        },
        downloadAttachment(attachment) {
            return this.$store.dispatch('attachmentDownloadRequest', { id: attachment.id}).then((data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', attachment.name);
                document.body.appendChild(link);
                link.click();
            });
        },
        urlify(text) {
            var urlRegex = /(https?:\/\/[^\s]+)/g;
            return text.replace(urlRegex, (url) =>'<a href="' + url + '" target="_blank">' + url + '</a>');
        }
    },
    watch: {
        // cannot put search in options, options watcher isn't deep enough
        search: {
            handler() {
                this.options.page = 1
                this.refreshPagination()
                this.$router.replace({
                    query: {
                        search: JSON.stringify(this.search),
                    }
                }).catch(e => console.log(e))
            },
            deep: true
        },
        // https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
        computedOptions: {
            handler (val, oldVal) {
                if (JSON.stringify(val) != JSON.stringify(oldVal)) {
                    if (val.sortDesc != oldVal.sortDesc)
                        this.options.page = 1
                    if (val.sortBy != oldVal.sortBy)
                        this.options.page = 1
                    if (! val.sortBy)
                        this.options.sortDesc = false

                    if (val.page == this.options.page) {
                        window.scrollTo(0, 0)

                        this.refreshPagination()

                        this.$router.replace({
                            query: {
                                page: this.options.page, 
                                search: JSON.stringify(this.search),
                                sortBy: this.options.sortBy,
                                sortDesc: this.options.sortDesc,
                            }
                        }).catch(e => console.log(e))
                    }
                }
            },
            deep: true
        },
    }
}
</script>

<template>
    <div class="workshops">
        <Navigation/>
        <v-main>
            <v-container v-if="$acl.not.check('isProPremium')">
                <v-row>
                    <v-col cols="12">
                        <NotPremiumCard/>
                    </v-col>
                </v-row>
            </v-container>

            <v-container v-else>
                <v-row>
                    <v-col cols=12>
                        <v-data-iterator 
                            :items="workshops"
                            :options.sync="options"
                            :server-items-length="total"
                            :loading="loading"
                            hide-default-footer
                        >
                            <template v-slot:header>
                                <v-card elevation="4" color="primary" class="mb-3">
                                    <v-card-text>
                                        <v-row no-gutters align="center" class="headline mb-3">
                                            <v-col cols="auto">
                                                <v-icon class="white--text mr-5" size="50px">$onsite_workshop</v-icon>
                                            </v-col>
                                            <v-col class="font-weight-bold white--text text-no-wrap">Mes ateliers</v-col>
                                        </v-row>
                                        <v-row dense align="center">
                                            <v-col style="min-width: 200px;">
                                                <v-autocomplete
                                                    v-model="search.zip"
                                                    :items="allDepartments"
                                                    :item-text="d => d.code+' - '+d.nom"
                                                    item-value="code"
                                                    label="Département"
                                                    :loading="departmentsLoading"
                                                    flat
                                                    solo
                                                    clearable
                                                    hide-details
                                                    no-data-text="Aucun département trouvé"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col style="min-width: 200px;">
                                                <v-autocomplete
                                                    v-model="search.city"
                                                    :items="allCities"
                                                    item-text="nom"
                                                    item-value="nom"
                                                    label="Ville"
                                                    :loading="citiesLoading"
                                                    flat
                                                    solo
                                                    clearable
                                                    hide-details
                                                    no-data-text="Aucune ville trouvée"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col style="min-width: 200px;">
                                                <v-autocomplete
                                                    v-model="search.partner_id"
                                                    :items="allPartners"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Centre de soins"
                                                    :loading="partnersLoading"
                                                    flat
                                                    solo
                                                    clearable
                                                    hide-details
                                                    no-data-text="Aucun centre de soins trouvé"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col style="min-width: 200px;">
                                                <v-select
                                                    v-model="search.dates"
                                                    :items="allDates"
                                                    label="Séances"
                                                    flat
                                                    solo
                                                    clearable
                                                    hide-details
                                                ></v-select>
                                            </v-col>
                                            <v-col style="min-width: 300px;">
                                                <v-row no-gutters align="center">
                                                    <v-col>
                                                        <v-select
                                                            v-model="options.sortBy"
                                                            clearable
                                                            flat
                                                            solo
                                                            hide-details
                                                            prepend-inner-icon="swap_vert"
                                                            label="Trier par"
                                                            :items="sortables"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <v-btn-toggle
                                                            v-model="options.sortDesc"
                                                            group
                                                            color="white"
                                                        >
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn :value="false" v-on="on">
                                                                        <v-icon>keyboard_arrow_up</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Croissant</span>
                                                            </v-tooltip>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn :value="true" v-on="on">
                                                                        <v-icon>keyboard_arrow_down</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Décroissant</span>
                                                            </v-tooltip>
                                                        </v-btn-toggle>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </template>

                            <template v-slot:default="props">
                                <v-row align="stretch">
                                    <v-col
                                        v-for="(workshop,k) in props.items"
                                        :key="workshop.id"
                                        cols="12"
                                        sm="6"
                                        md="4"
                                        lg="3"
                                    >
                                        <WorkshopCard ref="WorkshopCard" :key="workshop.id" v-model="workshops[k]" :type="workshops[k].type" :link="{ name: 'ProWorkshop', params: {id: workshop.id} }" color="secondary" show-complete/>
                                    </v-col>
                                </v-row>
                            </template>

                            <template v-slot:loading>
                                <v-row align="stretch">
                                    <v-col
                                        v-for="n in options.itemsPerPage"
                                        :key="n"
                                        cols="12"
                                        sm="12"
                                        md="6"
                                        lg="4"
                                    >
                                        <v-skeleton-loader type="article, list-item, list-item, list-item" height="400px" width="400px"></v-skeleton-loader>
                                    </v-col>
                                </v-row>
                                <!-- <v-row justify="center">
                                    <v-col cols="auto">
                                        <v-progress-circular
                                            indeterminate 
                                            color="secondary"
                                            :size="90"
                                            :width="8"
                                        ></v-progress-circular>
                                    </v-col>
                                </v-row> -->
                            </template>

                            <template v-slot:no-data>
                                <v-card elevation="4" class="mt-5">
                                    <v-card-text class="text-center">
                                        <v-icon x-large>sentiment_satisfied</v-icon>
                                        <br>
                                        <span class="title">
                                            Contactez l’administrateur d’onCOGITE, celui-ci créera votre premier atelier !
                                        </span>
                                    </v-card-text>
                                </v-card>
                            </template>

                            <template v-slot:footer>
                                <v-row v-if="!loading" class="mt-5" align="center" justify="center">
                                    <v-col cols="auto">
                                        <v-select 
                                            v-model="options.itemsPerPage"
                                            :items="[
                                                {value: 20, text: '20'},
                                                {value: 50, text: '50'},
                                                {value: -1, text: 'Tous'},
                                            ]"
                                            solo
                                            suffix="résultats par page"
                                            hide-details
                                            class="paginate"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn
                                            fab
                                            color="primary"
                                            class="mr-1"
                                            @click="prevPage"
                                            :disabled="options.page <= 1"
                                        >
                                            <v-icon x-large>keyboard_arrow_left</v-icon>
                                        </v-btn>
                                        <span class="mx-4 grey--text">
                                            Page {{ options.page }} sur {{ lastPage }}
                                        </span>
                                        <v-btn
                                            fab
                                            color="primary"
                                            class="ml-1"
                                            @click="nextPage"
                                            :disabled="options.page == lastPage"
                                        >
                                            <v-icon x-large>keyboard_arrow_right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-data-iterator>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import WorkshopCard from '@/components/workshops/Card'
export default {
    components: {
        WorkshopCard,
    },
    data () {
        return {
            ready: false,
            workshops: [],
            options: {
                page: parseInt(this.$route.query.page) || 1,
                itemsPerPage: 20,
                sortBy: this.$route.query.sortBy || [],
                sortDesc: this.$route.query.sortDesc == 'true',
                groupBy: [],
                groupDesc: [],
                mustSort: false,
                multiSort: false,
            },
            search: this.$route.query.search ? JSON.parse(this.$route.query.search) : {},
            sortables: [
                {value: 'name', text: 'Ordre alphabétique'},
                {value: 'zip', text: 'Code postal'},
                {value: 'next_meeting_start', text: 'Prochaine séance'},
            ],
            total: 0,
            isMobile: false,
            loading: false,
            allDepartments: [],
            allCities: [],
            allPartners: [],
            allDates: [],
            departmentsLoading: false,
            citiesLoading: false,
            partnersLoading: false,
        }
    },
    mounted () {
        this.refreshPagination()
        this.initAdvancedSearch()
    },
    computed: {
        lastPage() {
            return Math.ceil(this.total / this.options.itemsPerPage)
        },
        // https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
        computedOptions: function() {
            return Object.assign({}, this.options);
        }
    },
    methods: {
        prevPage() {
            if (this.options.page - 1 >= 1) this.options.page -= 1
        },
        nextPage() {
            if (this.options.page + 1 <= this.lastPage) this.options.page += 1
        },
        refreshPagination () {
            const queries = {
                page: this.options.page,
                per_page: this.options.itemsPerPage,
                sort: this.options.sortBy,
                direction: this.options.sortDesc ? 'desc' : 'asc',
                search: JSON.stringify({...this.search, user_id: this.$store.getters.getProfile.id}),
                with: 'user',
            }

            this.loading = true
            this.workshops = []
            this.$store.dispatch('workshopsRequest', queries)
                .then(result => {
                    if (! result.data.length && this.options.page > 1)
                        this.options.page--

                    if (! result.data.length)
                        this.options.page = 0

                    this.total = result.meta.total
                    this.workshops = result.data
                    this.loading = false
                    this.ready = true;
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        initAdvancedSearch() {
            this.fetchDepartments()
            this.fetchCities()
            this.fetchPartners()
            // init dates items
            this.allDates = [
                {
                    text: 'Cette semaine', 
                    value: [
                        this.$moment().startOf('week').format('YYYY-MM-DD'), 
                        this.$moment().endOf('week').format('YYYY-MM-DD')
                    ]
                },
                {
                    text: 'La semaine prochaine', 
                    value: [
                        this.$moment().add(1, 'week').startOf('week').format('YYYY-MM-DD'),
                        this.$moment().add(1, 'week').endOf('week').format('YYYY-MM-DD'),
                    ]
                },
                {
                    text: 'Ce mois-ci', 
                    value: [
                        this.$moment().startOf('month').format('YYYY-MM-DD'),
                        this.$moment().endOf('month').format('YYYY-MM-DD'),
                    ]
                },
                {
                    text: 'Le mois prochain', 
                    value: [
                        this.$moment().add(1, 'month').startOf('month').format('YYYY-MM-DD'),
                        this.$moment().add(1, 'month').endOf('month').format('YYYY-MM-DD'),
                    ]
                },
            ]
        },
        fetchDepartments(event) {
            this.departmentsLoading = 'secondary'
            this.$axios.get('https://geo.api.gouv.fr/departements')
                .then((resp) => {
                    this.allDepartments = resp.data
                }).finally(() => {
                    this.departmentsLoading = false
                })
        },
        fetchCities(event) {
            this.citiesLoading = 'secondary'
            this.$axios.get('https://geo.api.gouv.fr/communes')
                .then((resp) => {
                    this.allCities = resp.data
                }).finally(() => {
                    this.citiesLoading = false
                })
        },
        fetchPartners(event) {
            this.partnersLoading = 'secondary'
            this.$store.dispatch('partnersRequest', {search: JSON.stringify({'type': 'healthcenter'})})
                .then((result) => {
                    this.allPartners = result.data
                })
                .catch((err) => {
                }).finally(() => {
                    this.partnersLoading = false
                })
        }
    },
    watch: {
        // cannot put search in options, options watcher isn't deep enough
        search: {
            handler() {
                this.options.page = 1
                this.refreshPagination()
                this.$router.replace({
                    query: {
                        search: JSON.stringify(this.search),
                    }
                }).catch(e => console.log(e))
            },
            deep: true
        },
        // https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
        computedOptions: {
            handler (val, oldVal) {
                if (JSON.stringify(val) != JSON.stringify(oldVal)) {
                    if (val.sortDesc != oldVal.sortDesc)
                        this.options.page = 1
                    if (val.sortBy != oldVal.sortBy)
                        this.options.page = 1
                    if (! val.sortBy)
                        this.options.sortDesc = false

                    if (val.page == this.options.page) {
                        window.scrollTo(0, 0)
                        // window.scrollTo({ top: 0, behavior: 'smooth' })
                        // await this.$vuetify.goTo(0)

                        this.refreshPagination()

                        this.$router.replace({
                            query: {
                                page: this.options.page, 
                                search: JSON.stringify(this.search),
                                sortBy: this.options.sortBy,
                                sortDesc: this.options.sortDesc,
                            }
                        }).catch(e => console.log(e))
                    }
                }
            },
            deep: true
        },
    }
}
</script>

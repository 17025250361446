import Vue from 'vue'
import Router from 'vue-router'
import store from './vuex/store'
import Login from './views/Login.vue'
import Register from './views/Register.vue'
import PasswordReset from './views/PasswordReset.vue'
import PasswordEdit from './views/PasswordEdit.vue'
import Home from './views/Home.vue'
import Payment from './views/Payment.vue'
import Payments from './views/Payments.vue'
import Unauthorized from './views/Unauthorized.vue'
import Profile from './views/Profile.vue'
import ProfileEdit from './views/ProfileEdit.vue'
import WorkshopsMap from './views/WorkshopsMap.vue'
import Workshops from './views/Workshops.vue'
import Workshop from './views/Workshop.vue'
import Meetings from './views/Meetings.vue'
import ProWorkshops from './views/Pro/Workshops.vue'
import ProWorkshop from './views/Pro/Workshop.vue'
import ProUser from './views/Pro/User.vue'
import ProUsers from './views/Pro/Users.vue'
import SatisfactionSurvey from './views/User/SatisfactionSurvey.vue'
import ProfileSurvey from './views/User/ProfileSurvey.vue'
import Welcome from './views/Welcome.vue'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/unauthorized',
            name: 'Unauthorized',
            component: Unauthorized,
            meta: {
                rule: ['*']
            },
        },
        {
            path: '/',
            redirect: 'home',
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                rule: 'isNotAuth'
            },
        },
        {
            path: '/password/reset',
            name: 'password-reset',
            component: PasswordReset,
            meta: {
                rule: 'isNotAuth'
            }
        },
        {
            path: '/password/reset/:token',
            name: 'password-edit',
            component: PasswordEdit,
            meta: {
                rule: 'isNotAuth'
            }
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                rule: 'isNotAuth'
            }
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/welcome',
            name: 'Welcome',
            component: Welcome,
            meta: {
                rule: 'isAuth'
            }
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile,
            meta: {
                rule: 'isAuth'
            }
        },
        {
            path: '/profile/edit',
            name: 'ProfileEdit',
            component: ProfileEdit,
            meta: {
                rule: 'isAuth'
            }
        },
        {
            path: '/payment',
            name: 'Payment',
            component: Payment,
            meta: {
                rule: ['isAuth']
            }
        },
        {
            path: '/payments',
            name: 'Payments',
            component: Payments,
            meta: {
                rule: ['isAuth']
            }
        },
        // {
        //     path: '/meetings/:user_id?',
        //     name: 'Meetings',
        //     component: Meetings,
        //     meta: {
        //         rule: ['isAuth']
        //     }
        // },
        {
            path: '/meetings',
            name: 'Meetings',
            component: Meetings,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/meetings/:user_id',
            props: true,
            name: 'ProMeetings',
            component: Meetings,
            meta: {
                rule: ['isPro']
            }
        },
        {
            path: '/map',
            name: 'WorkshopsMap',
            component: WorkshopsMap,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/workshops',
            component: Workshops,
            name: 'Workshops',
            children: [
                {
                    path: 'aja',
                    name: 'AJAWorkshops',
                    meta: {
                        rule: ['*']
                    }
                },
                {
                    path: 'list',
                    name: 'ListWorkshops',
                    meta: {
                        rule: ['*']
                    }
                },
            ],
        },
        {
            path: '/workshops/:id',
            props: true,
            name: 'Workshop',
            component: Workshop,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/pro/workshops',
            name: 'ProWorkshops',
            component: ProWorkshops,
            meta: {
                rule: 'isPro'
            }
        },
        {
            path: '/pro/workshops/:id',
            props: true,
            name: 'ProWorkshop',
            component: ProWorkshop,
            meta: {
                rule: 'isPro'
            }
        },
        {
            path: '/pro/users',
            name: 'ProUsers',
            component: ProUsers,
            meta: {
                rule: 'isPro'
            }
        },
        {
            path: '/user/:id',
            props: true,
            name: 'ProUser',
            component: ProUser,
            meta: {
                rule: 'isPro'
            }
        },
        {
            path: '/satisfaction_survey',
            name: 'SatisfactionSurvey',
            component: SatisfactionSurvey,
            meta: {
                rule: 'isUserPremium'
            }
        },
        {
            path: '/profile_survey',
            name: 'ProfileSurvey',
            component: ProfileSurvey,
            meta: {
                rule: 'isUserPremium'
            }
        },
        {
            path: '/oncogitiel',
            name: 'Oncogitiel',
            meta: {
                rule: ['*']
            },
            beforeEnter: (to, from, next) => {
                if(localStorage.getItem('access_token')){
                    window.location.href = `${process.env.VUE_APP_ONCOGITIEL_URL}/?access_token=${localStorage.getItem('access_token')}&expires_in=${localStorage.getItem('expires_in')}`;
                }else{
                    window.location.href = `${process.env.VUE_APP_ONCOGITIEL_URL}`;
                }
            }
        },
    ]
})

router.beforeEach((to, from, next) => {
    if(to.query.access_token) {
        store.commit('authSuccess', {access_token: to.query.access_token })
        store.dispatch('profileRequest').then(() => {
            next()
        })
        return
    }
    if ([from.name, to.name].indexOf('Unauthorized') < 0) {
        if (to.name == 'login') {
            store.commit('setFromRoute', to)
            store.commit('setToRoute', from)

        } else {
            store.commit('setFromRoute', from)
            store.commit('setToRoute', to)
        }
    }
    next()
})

export default router
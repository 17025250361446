<template>
    <div class="register d-flex flex-column fill-height">
        <Navigation/>
        <v-main class="grow">
            <v-container fluid class="fill-height align-start pb-0">
                <v-row justify="center" class="fill-height">
                    <v-col cols="12">
                        <v-card v-if="showPaymentCard">
                            <CardTitle v-if="isSubscription" text="Je finalise mon adhésion"/>
                            <CardTitle v-else text="Faire un don"/>

                            <v-card-text v-if="showAdhSecretCodeForm">
                                <v-row>
                                <v-col cols="2" />
                                    <v-col cols="8">
                                            <h3 v-if="isLigue" class="mb-8">Votre
                                                comité départemental vous offre votre cotisation, il
                                                sera averti de votre adhésion. </h3>
                                            <h3 v-else-if="isRetreat && retreatPartners.length > 0 && registration_process.partner.id"
                                                class="mb-8">
                                                Adhésion offerte par {{ retreatPartners.find(p => p.id ==
                                                    registration_process.partner.id).name }}
                                            </h3>
                                            <v-form ref="adhCodeForm" v-on:submit.prevent="onSubmit">
                                                <v-autocomplete v-if="isLigue" :rules="[rules.required]"
                                                    v-model="registration_process.partner.id" outlined
                                                    :items="liguePartners" :item-text="formatZipCode" item-value="id"
                                                    label="Merci de selectionner le code postal de votre comité">
                                                </v-autocomplete>
                                                <v-text-field v-if="isLigue && liguePartners"
                                                    v-model="registration_process.partner.adh_secret_code" outlined
                                                    :rules="[rules.required]"
                                                    label="Merci de saisir le code fourni par votre comité"></v-text-field>

                                                <v-text-field
                                                    v-else-if="isRetreat && retreatPartners && retreatPartners.length > 0"
                                                    v-model="registration_process.partner.adh_secret_code" outlined
                                                    :rules="[rules.required]" :label="'Merci de saisir le code fourni par ' + retreatPartners.find(p => p.id ==
                                                        registration_process.partner.id).name"></v-text-field>
                                                <p class="mb-8" v-if="isLigue">Le code secret n'est pas conforme ou ne m'a pas été communiqué, 
                                                    <a target="_blank" href="https://www.ligue-cancer.net/la-ligue-pres-de-chez-vous-comite">je contacte mon comité départemental</a>
                                                </p>
                                            </v-form>
                                            <v-btn color="primary" @click="checkAdhSecretCodeFormAndNextStep">
                                                Valider
                                            </v-btn>
                                            <v-btn text @click="openChangeReasonDialog()">
                                                Modifier mon orientation
                                            </v-btn>
                                    </v-col>
                                    <v-col cols="2" />
                                    </v-row>
                            </v-card-text>
                            <v-card-text v-else-if="showQprSurvey">
                                <v-row>
                                    <v-col cols="2" />
                                    <v-col cols="8">
                                        <Survey v-if="showQprSurvey" v-model="survey" :from_registration="true" @saved="onSaveSurveyCloseDialog" />
                                    </v-col>
                                    <v-col cols="2" />
                                </v-row>
                            </v-card-text>
                           
                            <v-card-text v-else>
                                <v-form v-on:submit.prevent="onSubmit" ref="form">
                                    <v-row v-if="isSubscription" justify="space-between" align="center" dense>
                                        <v-col cols="12">
                                            <p v-if="$acl.check('isUser')" class="mt-3 mb-0 subtitle-1">Profitez de toutes les fonctionnalités de l'outil en ligne OncoGite ! Inscription en ligne aux ateliers, suivi et rappel de vos séances,...</p>
                                            <p v-if="$acl.check('isPro')" class="mt-3 mb-0 subtitle-1">Vous pourrez ensuite créer vos atelier, les séances et gérer vos patients bénéficiares,...</p>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-switch 
                                                v-model="isSubscription"
                                                label="Adhésion"
                                                class="font-weight-bold"
                                            ></v-switch>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-text-field
                                                v-if="isSubscription"
                                                v-model="subscription"
                                                label="Montant de l'adhésion"
                                                outlined
                                                disabled
                                                append-icon="euro"
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="showDiscountAlert" justify="space-between" align="center" dense>
                                        <v-col cols="12">
                                            <v-alert color="primary" text dense prominent outlined icon="thumb_up">
                                                <b>Vous bénéficiez de l'adhésion à titre gracieux.</b>
                                               
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                    <hr>
                                    <v-row justify="space-between" align="center" dense>
                                        <v-col cols="12">
                                            <p class="mt-3 mb-0 subtitle-1">Notre association est reconnue d’intérêt général. Chaque don donne lieu à une réduction d’impôts. Pour les particuliers : pour 100€ versés à <oncogite/>… votre don ne vous coûte que 34€ !</p>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-switch 
                                                v-model="isDonation"
                                                label="Don"
                                                class="font-weight-bold"
                                            ></v-switch>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-text-field
                                                v-if="isDonation"
                                                v-model="donation"
                                                label="Montant du don"
                                                outlined
                                                append-icon="euro"
                                                hide-details
                                                type="number"
                                                min="0"
                                            ></v-text-field>
                                            <!-- <v-combobox
                                                v-if="isDonation"
                                                v-model="donation"
                                                :items="[10, 50, 100, 200, 300]"
                                                label="Montant du don"
                                                type="number"
                                                :rules="[rules.positive]"
                                                append-icon="euro"
                                                hide-details
                                                outlined
                                            ></v-combobox> -->
                                        </v-col>
                                    </v-row>
                                    <hr>
                                    <v-row align="center">
                                        <v-col  v-if="isSubscription"  cols="12">
                                            <h3 class="mt-6 mb-2">CHARTE D’ACCÈS À LA PLATEFORME FORMATION</h3>
                                            <b>Je m’engage à :</b>
                                            <v-checkbox v-if="isSubscription" v-model="platform_accepted_terms.access" class="ml-4 mt-1">
                                                <template v-slot:label>
                                                    <div>Ne pas partager les liens d’accès à quiconque qui ne serait pas inscrit à cette formation*</div>
                                                </template>
                                            </v-checkbox>
                                            <v-checkbox v-if="isSubscription" v-model="platform_accepted_terms.secret" class="ml-4 mt-n4">
                                                <template v-slot:label>
                                                    <div>
                                                        À respecter le principe du secret professionnel ou secret partagé afin
                                                        qu’aucune information ou partage ultérieur sur ce qui a été abordé en formation <br/>
                                                        ne permette l’identification de la personne ou ne puisse engendrer une
                                                        quelconque malveillance*
                                                    </div>
                                                </template>
                                            </v-checkbox>
                                            <v-checkbox v-if="isSubscription" v-model="platform_accepted_terms.discreet" class="ml-4 mt-n4">
                                                <template v-slot:label>
                                                    <div> 
                                                        À me tenir dans un lieu discret à l’abris de regards extérieurs lors ma
                                                        participation*
                                                    </div>
                                                </template>
                                            </v-checkbox>
                                            <v-checkbox v-if="isSubscription" v-model="platform_accepted_terms.sceenshots" class="ml-4 mt-n4">
                                                <template v-slot:label>
                                                    <div style="display: flex; align-items: baseline;">
                                                        À ne procéder à aucune capture d’écran, photographie ou vidéo des participants
                                                        ou des supports (diaporama, exercices ou guide d’animation)*
                                                    </div>
                                                </template>
                                            </v-checkbox>
                                            Je comprends que cette démarche s’inscrit dans le cadre strict du respect des
                                            personnes et du matériel qui est mis à ma disposition.
                                            Le non-respect de l’intégralité de la charte donnera lieu à une interdiction d’accès à
                                            la plateforme, à ses contenus et à toute demande de remboursement.
                                        </v-col>
                                        <v-checkbox v-if="isSubscription && !$acl.check('isPro') && !isLigue" v-model="followed_cancerous_pathology" class="ml-4 mt-n4">
                                            <template v-slot:label>
                                                <div>Je certifie que j'ai été ou suis suivi.e pour une pathologie cancéreuse*</div>
                                                <!-- <div>Je certifie que j'ai été ou suis suivi.e pour une pathologie cancéreuse {{ reasonValidation }}*</div> -->
                                            </template>
                                        </v-checkbox>
                                        <v-col class="text-right pb-5 mb-5 subtitle-1">
                                            <b>Total:</b>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-text-field
                                                class="mt-6"
                                                :value="total"
                                                :rules="[]"
                                                type="number"
                                                label="Montant total"
                                                outlined
                                                disabled
                                                append-icon="euro"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn v-if="isSubscription" style="margin-top: -120px; margin-left: -10x" depressed @click="openChangeReasonDialog()">
                                                Modifier mon orientation
                                            </v-btn>
                                        </v-col>
                                        <v-col v-if="total > 0" cols="12" class="text-center">
                                            Une fois votre choix validé, vous serez redirigé vers une page de paiement sécurisée.
                                        </v-col>
                                    </v-row>
                                    <v-tooltip top :disabled="((isSubscription && (followed_cancerous_pathology && allPlatformTermsAccepted) || ($acl.check('isPro') && allPlatformTermsAccepted)))">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <v-btn v-on="on" type="submit" block color="primary" :loading="loading"
                                                    :disabled="(isSubscription && ((!followed_cancerous_pathology && !$acl.check('isPro')) || !allPlatformTermsAccepted))"
                                                >Valider</v-btn>
                                            </div>
                                        </template>
                                        <span>Vous devez accepter les conditions ci-dessus avant de pouvoir continuer.</span>
                                    </v-tooltip>
                                </v-form> 

                                <form ref="etransactionsForm" method="POST" :action="eTransactions.url">
                                    <input v-for="(data, i) in eTransactions.datas" :key="i"
                                        type="hidden"
                                        :name="data.key" 
                                        :value="data.value">
                                    <input type="hidden" name="PBX_HMAC" :value="eTransactions.hmac">
                                </form>
                            </v-card-text>
                        </v-card>
                        <v-dialog  v-model="showBravoCard" fullscreen hide-overlay transition="dialog-bottom-transition" persistent >
                            <v-card v-if="showBravoCard" class="fill-height">
                                <CardTitle text="Bravo"/>
                                <v-card-text class="text-center">
                                    <template v-if="$acl.check('isUser')">
                                        <v-card color="transparent" class="elevation-0" style="z-index: 1;">
                                             <v-card-text > 
                                                <div class="body-1">
                                                    <p class="mb-0">
                                                        Bienvenue dans le monde des COGITEUSES et COGITEURS…
                                                    </p>
                                                    <p class="mb-0">
                                                        Vous allez commencer votre travail de reconnexion des neurones !
                                                    </p>
                                                    <p>
                                                        <b>En tout premier lieu, nous allons faire un peu connaissance, grâce à votre PROFIL</b>
                                                    </p>
                                                    <p class="text-center">
                                                        <b>Pourquoi remplir mon PROFIL ?</b>
                                                    </p>
                                                    <p class="mb-0">
                                                        Ces informations sont <b>importantes</b> pour nous aider à <b>porter votre cause</b> auprès des politiques, des organismes d’assurance maladie, des agences régionales de soins, des mutuelles et des prévoyances. 
                                                    </p>
                                                    <p>
                                                        Grâce à cela, nous avancerons ensemble et plus loin pour permettre la reconnaissance des troubles onCOGITIFS et ainsi continuer à accompagner tous les futurs patients, comme vous aujourd’hui.
                                                    </p>
                                                </div>
                                                <v-btn color="primary" :to="{name: 'ProfileSurvey'}">Accéder à mon profil</v-btn>
                                                <div class="body-1 mt-10 text-center">
                                                    <p>
                                                        Vous ne souhaitez pas remplir votre profil.<br/>
                                                        Ce n’est pas un problème, contactez SOPHIE PRADA au 06 68 91 53 15:<br/>
                                                        Elle vous accompagnera pour choisir votre atelier et faire votre première inscription.
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                    <template v-if="$acl.check('isPro')">
                                        <v-card color="transparent" class="elevation-0" style="z-index: 1;">
                                             <v-card-text > 
                                                <div class="body-1">
                                                    <p class="mb-8">
                                                        Vous faites partie de l’équipe des Animateurs-Cogiteurs(euses).
                                                    </p>
                                                    <p class="mb-8">
                                                        Prenez le temps de trouver vos marques sur la plateforme. Notre équipe est là pour vous aider et vous accompagner.
                                                    </p>
                                                    <p class="mb-8">
                                                        N’hésitez pas à contacter Sophie par mail <a href="mailto:s.prada@oncogite.com">s.prada@oncogite.com</a> 
                                                        ou par tel <a href="tel:+33668915315">+33 6 68 91 53 15</a>
                                                    </p>
                                                    <p>
                                                        Sophie est chargée de la création de votre espace atelier. Après ce sera à vous de jouer : 
                                                    </p>
                                                    <p class="mt-n4">
                                                        - Créez et organisez les séances hebdomadaires de votre atelier 
                                                    </p>
                                                    <p class="mt-n4">
                                                        - Accédez aux supports numériques en lignes 
                                                    </p>
                                                    <p class="mt-n4 mb-12">
                                                        - Restez en contact avec les patients qui vous suivent.
                                                    </p>
                                                </div>
                                                <v-btn color="primary" :to="{name: 'ProWorkshops'}">Aller dans mon espace Pro</v-btn>
                                             </v-card-text>
                                        </v-card> 
                                    </template>
                                </v-card-text>
                                <v-footer style="z-index: 0" fixed color="transparent" class="pb-7">
                                    <v-img :src="require('@/assets/images/bandeau_bravo.png')" contain></v-img>
                                </v-footer>
                            </v-card>
                        </v-dialog>
                        <v-card v-if="showThanksCard" class="fill-height">
                            <CardTitle text="Merci !"/>
                            <v-card-text>
                                <div class="body-1 text-center">
                                    <p>Vous faites partie de nos mécènes et bienfaiteurs onCOGITEURS !</p>
                                    <p>Grâce à vous, onCOGITE va poursuivre plus loin encore ses actions,</p>
                                    <p>Plein d’autres patients pourront, comme vous, reconnecter leurs neurones et reprendre le cours de leur vie.</p>
                                    <p>Merci de votre confiance.</p>
                                </div>
                            </v-card-text>
                            <v-footer fixed color="transparent" class="pb-7">
                                <v-img :src="require('@/assets/images/bandeau_merci.png')" contain></v-img>
                            </v-footer>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-dialog persistent v-model="changeReasonDialog" max-width="800">
                <v-card class="text-center pt-6">
                    <v-card-text >
                        <div>
                            <h2>Modifier le motif de ma visite</h2>
                        </div>
                        <SubscriptionReasonForm v-if="changeReasonDialog" :registration_process="registration_process" @cancelUpdateReason="changeReasonDialog = false"
                            @savedForm="handleSaveReasonForm" />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-main>
    </div>
</template>

<script>
import Survey from '@/components/surveys/Form'
import SubscriptionReasonForm from '@/components/SubscriptionReasonForm'
export default {
    components: {
        Survey,
        SubscriptionReasonForm,
    },
    data(){
        return {
            loading: false,
            isSubscription: false,
            isDonation: false,
            showPaymentCard: true,
            showBravoCard: false,
            showThanksCard: false,
            showDiscountAlert: false,
            subscription: null,
            donation: 30,
            discounted: 0,
            eTransactions: [],
            rules: {
                positive: value => !!value || (value && value >= 1) || 'Montant minimum: 1€',
                required: value => !!value || 'Champ requis',
            },
            paymentContactMail: '',
            followed_cancerous_pathology: false,
            platform_accepted_terms: {access: false, secret: false, discreet: false, sceenshots: false },
            survey: {},
            partners: [],
            registration_process: JSON.parse(this.$store.getters.getProfile.registration_process),
            changeReasonDialog: false,
        }
    },
    computed: {
        total() {
            return parseFloat((this.isSubscription ? this.subscription : 0)) + parseFloat((this.isDonation ? this.donation : 0))
        },
        allPlatformTermsAccepted() {
            const terms = this.platform_accepted_terms;
            return Object.values(terms).every(term => term === true);
        },
        isLigue() {
            return this.registration_process?.reason == "ligue"
        },
        isEmployer() {
            return this.registration_process?.reason == "employer"
        },
        isHealthinsurance() {
            return this.registration_process.reason == "healthinsurance"
        },
        isRetreat() {
            return this.registration_process?.reason == "retreat"
        },
        showQprSurvey() {
            return this.isSubscription && this.$acl.check('isUser') && (this.isLigue) && !this.registration_process.qprRegistered
        },
        showAdhSecretCodeForm() {
            return this.isSubscription && this.$acl.check('isUser') && (this.isLigue || this.isRetreat) && !this.registration_process.validCodeRegistered
        },
        liguePartners() {
            return this.partners.filter(p => p.support_type == 'ligue')
        },
        retreatPartners() {
            return this.partners.filter(p => p.support_type == 'retreat')
        },
        // reasonValidation () {
        //     if (this.isLigue) {
        //         return " et que je suis envoyé par la ligue"
        //     } else if (this.isRetreat) {
        //         return "et que ma complementaire santé est " + this.partners.find(p => p.id == this.registration_process?.partner.id)?.name
        //     } else if (this.isEmployer) {
        //         return "et que mon employeur est " + this.partners.find(p => p.id == this.registration_process?.partner.id)?.name
        //     } else if (this.isHealthinsurance) {
        //         return "et que mon ma couverture santé prévoyance est " + this.partners.find(p => p.id == this.registration_process?.partner.id)?.name
        //     } else {
        //         return ""
        //     }
        // }
    },
    watch: {
        donation(val) {
            if (!val) this.donation = 0
        }
    },
    async mounted() {
        // payment response
        if (this.$route.query.code) {
            if (this.$route.query.code === '00000'){
                this.$store.dispatch('profileRequest')
                    .then(() => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': 'Paiement effectué !'});
                        this.showPaymentCard = false
                        if (this.$route.query.subscription)
                            this.showBravoCard = true
                        else
                            this.showThanksCard = true
                    })
            } else {
                this.$store.dispatch('setErrors', {'message': 'Erreur lors du paiement'})
            }
        } else {
            // check action to do
            if (this.$route.query.subscription && !this.$store.getters.getProfile.types.includes("premium")) {
                this.isSubscription = true
                if(!this.showQprSurvey) {
                    this.showPaymentCard = true
                }
            }
            if (this.$route.query.donation || this.$acl.check('isUserPremium') || this.$acl.check('isProPremium') || ! this.isSubscription) {
                this.isDonation = true
                if (this.$route.query.donation)
                    this.donation = parseFloat(this.$route.query.donation)
            }
        }
        this.initData()
    },
    methods: {
        initData() {
            this.fetchPaymentContactMail()
            this.getPartners();

            if(JSON.parse(this.$store.getters.getProfile.registration_process)?.reason == "ligue") {
                this.followed_cancerous_pathology = true
            }

            if (this.isSubscription && JSON.parse(this.$store.getters.getProfile.registration_process)?.partner.id) {
                this.fetchPartnerAmount()
            }
            else {
                this.fetchSubscriptionAmount()
            }           

            if(this.showQprSurvey) {
                this.fetchSurvey()
            }
        },
        formatZipCode(item) {
            if (!item || !item.zip) {
                return ''
            }
            //Spécific cases
            if (item.zip.substring(0, 2) == "97" || item.zip.substring(0, 2) == "98" ) {
                return item ? item?.zip?.slice(0, 3) : '';
            } else {
                return item ? item?.zip?.slice(0, 2) : '';
            }
        },
        async onSubmit() {
            await this.wsConnection()
            if (this.$refs.form.validate()) {
                this.loading = true
                let urlParams = []
                if (this.isSubscription)
                    urlParams.push('subscription=1')
                if (this.isDonation)
                    urlParams.push('donation='+(this.isDonation ? this.donation : 0))

                let queries = {
                    user_id: this.$store.getters.getProfile.id,
                    redirect_url: process.env.VUE_APP_URL+'/#/payment'+(urlParams.length ? '?'+urlParams.join('&') : ''),
                    subscription: this.isSubscription ? this.subscription : 0,
                    donation: this.isDonation ? this.donation : 0,
                    discounted: this.discounted,
                }
                if (this.total > 0) {
                    this.eTransactionRequest(queries)
                } else {
                    if(this.isSubscription) {
                        this.freeAdhRequest()
                    } else {
                        this.$store.dispatch('setErrors', "Le montant de votre don doit être supérieur à 1€")
                        this.loading = false
                    }  
                }
            }
        },
        freeAdhRequest() {
            let request = 'freeAdhesionRequest'
                let queries = {
                    user_id: this.$store.getters.getProfile.id,
                    partner_id: JSON.parse(this.$store.getters.getProfile.registration_process).partner.id,
                    adh_secret_code: JSON.parse(this.$store.getters.getProfile.registration_process).partner.adh_secret_code,
                    reason: JSON.parse(this.$store.getters.getProfile.registration_process).reason,
                    ref: 'ONCOMAN' + Date.now().toString(),
                    note:  `Adhesion gracieuse offerte par ${this.partners.find(p => p.id == this.registration_process.partner.id).name}`
                }
                this.$store.dispatch(request, queries)
                    .then(() => {
                        setTimeout(() => {
                            this.$store.commit('setSnack', {'type': 'success', 'msg': 'Adhésion enregistrée !'});
                            let userQueries = {
                                id: this.$store.getters.getProfile.id, 
                                datas: { ...this.$store.getters.getProfile, 
                                    followed_cancerous_pathology: this.followed_cancerous_pathology,
                                    platform_access_terms_accepted: this.allPlatformTermsAccepted
                                } 
                            }
                            this.$store.dispatch('userEditRequest', userQueries)
                            this.loading = false
                            this.$router.push({name: 'Home'});
                        }, 3000);
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.$store.dispatch('setErrors', err.response.data)
                    })

        },
        eTransactionRequest(queries) {
            this.$store.dispatch('etransactionsCreateRequest', queries)
                    .then((eTransactions) => {
                        let userQueries = {
                            id: this.$store.getters.getProfile.id, 
                            datas: { ...this.$store.getters.getProfile, 
                                followed_cancerous_pathology: this.followed_cancerous_pathology,
                                platform_access_terms_accepted: this.allPlatformTermsAccepted
                            } 
                        }
                        this.$store.dispatch('userEditRequest', userQueries)
                        this.eTransactions = eTransactions
                        setTimeout(() => this.$refs.etransactionsForm.submit(), 3000);
                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.response.status != 403)
                            this.$store.dispatch('setErrors', err.response.data)
                    })
        },
        fetchSubscriptionAmount() {
            let setting = 'subscription_amount'
            if (this.$acl.check('isPro'))
                setting = 'pro_'+setting

            this.$store.dispatch('settingRequest', {key: setting})
                .then((value) => {
                    this.subscription = parseFloat(value)
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        getPartners() {
            this.$store.dispatch('partnersRequest', {})
                .then((result) => this.partners = result.data)
                .catch(() => this.$store.dispatch('setErrors', 'Error while fetching partners'))
        },
        fetchPartnerAmount() {
            this.$store.dispatch('partnerRequest', {id: JSON.parse(this.$store.getters.getProfile.registration_process).partner.id})
                .then(result => {
                    this.discounted = 1
                    let partnerPrice = parseFloat(result.data.price)
                    if (partnerPrice > 0) {
                        this.subscription = partnerPrice
                    } else {
                        if (partnerPrice === 0) {
                            this.subscription = partnerPrice
                            this.showDiscountAlert = true
                        }
                    }
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        fetchPaymentContactMail() {
            this.$store.dispatch('settingRequest', {key: 'payment_contact_mail'})
                .then((value) => {
                    this.paymentContactMail = value
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        async fetchSurvey() {
            this.$store.dispatch('surveyRequest', { id: 2, with: ['survey_questions'] })
                .then(result => {
                    this.survey = result.data
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        onSaveSurveyCloseDialog() {
            this.$store.dispatch('updateQprRegistered');
            this.$set(this.registration_process, 'qprRegistered', true);
            this.initData()
        },
        async checkAdhSecretCodeFormAndNextStep() {
            if (this.$refs.adhCodeForm.validate()) {
             this.$store.dispatch('partnerCheckAdhSecretCode', this.registration_process.partner)
                .then(() => {
                    this.$store.dispatch('updateValidCodeRegistered', this.registration_process.partner);
                    this.$set(this.registration_process, 'validCodeRegistered', true);
                })
                .catch((err) => {                   
                    let errorMsg = `${err.response.data.error}, renseignez-vous auprès de ${this.partners.find(p => p.id == this.registration_process.partner.id).name}`
                    this.$store.dispatch('setErrors', errorMsg)
                })
            }
        },
        openChangeReasonDialog() {
            this.changeReasonDialog = true
        },
        handleSaveReasonForm(registrationProcess) {
            this.registration_process = registrationProcess
            this.changeReasonDialog = false
            this.initData()
        },
        wsConnection() {
        if (this.$store.getters.getProfile.id) {
            return this.$store.dispatch('websocket/join', { uid: this.$store.getters.getProfile.id });
        } else {
            this.$store.dispatch('setErrors', "Gateway connexion error, please refresh the page and retry")
        }
},
    }
}
</script>
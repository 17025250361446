<template>
    <div class="home">
        <Navigation/>
        <v-main>
            <v-container>
                <v-row justify="center" class="mb-5">
                    <v-col cols="12">
                        <v-img v-if="$vuetify.theme.dark" :src="require('@/assets/images/full_logo_white.png')" max-height="300" contain></v-img>
                        <v-img v-else :src="require('@/assets/images/full_logo_grey.png')" max-height="300" contain></v-img>
                    </v-col>
                </v-row>

                <v-row justify="center" class="px-3">
                    <v-col cols="12" sm="auto">
                        <v-btn 
                            x-large 
                            block 
                            color="primary"
                            class="mb-3"
                            v-if="$acl.check('isNotAuth')"
                            :to="{name: 'register'}"
                        >
                            Adhérer
                        </v-btn>
                        <v-btn 
                            x-large 
                            block 
                            color="primary"
                            class="mb-3"
                            v-else-if="$acl.not.check('isUserPremium') && $acl.not.check('isProPremium')"
                            :to="{name: 'Payment', query: {subscription: 1}}"
                        >
                            Adhérer
                        </v-btn>
                        <v-btn 
                            x-large 
                            block 
                            color="primary"
                            class="mb-3"
                            v-else-if="showQrProfilAlert"
                            :to="{ name: 'ProfileSurvey' }"
                        >
                        <v-icon class="ml-n4 mr-2" color="white" size="30">warning</v-icon>
                            Questionnaire de profil
                        </v-btn>

                        <v-row v-if="$acl.check('isUser')" >
                            <v-col cols="6">
                                <v-btn x-large block color="primary" class="mb-3" :to="{name: 'ListWorkshops'}">
                                    Les Ateliers 
                                </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn x-large block color="primary" class="mb-3" :to="{name: 'Oncogitiel'}">
                                    onCogitiel
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row v-if="$acl.check('isPro')">
                            <v-col cols="6">
                                <v-btn x-large block color="secondary" class="mb-3" :to="{name: 'ProWorkshops'}">
                                    Mes ateliers
                                </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn x-large block color="secondary" class="mb-3" :to="{name: 'ProUsers'}">
                                    Mes adhérents
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>

        <my-footer/>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 
        }
    },
    mounted() {

    },
    computed: {
        showQrProfilAlert(){
            return this.$acl.check('isAuth') 
                && this.$acl.check('isUserPremium') 
                && this.$acl.not.check('isProPremium')
                && this.$store.getters.getProfile.qr_info_alert == true
        }
    },
    methods: {}
}
</script>

import Vue from 'vue'

const state = {
    profile: localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : '',
}

const getters = {
    getProfile: state => state.profile,
}

const actions = {
    profileRequest: async ({commit, dispatch}) => {
        await Vue.axios.get('/api/user')
            .then((resp) => {
                commit('profileSuccess', resp.data.data)
            })
            .catch(() => {
                // if resp is unauthorized, logout, to
                dispatch('authLogout')
            })
    },
    updateRegistrationProcessReason({ commit, state }, data) {
        const profile = state.profile;
        const registrationProcess = JSON.parse(profile.registration_process);
        registrationProcess.partner = data.partner
        registrationProcess.reason = data.reason
        profile.registration_process = JSON.stringify(registrationProcess);
        commit('profileSuccess', profile);
        //Update User
        let userQueries = {
            id: this.getters.getProfile.id,
            datas: { ...this.getters.getProfile, 
                registration_process: profile.registration_process
            } 
        }
        this.dispatch('userEditRequest', userQueries)
    },
    updateQprRegistered({ commit, state }) {
        const profile = state.profile;
  
        const registrationProcess = JSON.parse(profile.registration_process);
        registrationProcess.qprRegistered = true;
  
        profile.registration_process = JSON.stringify(registrationProcess);
  
        commit('profileSuccess', profile);
    },
    updateValidCodeRegistered({ commit, state }, data) {
        const profile = state.profile;
  
        const registrationProcess = JSON.parse(profile.registration_process);
        registrationProcess.validCodeRegistered = true;
        registrationProcess.partner = data
  
        profile.registration_process = JSON.stringify(registrationProcess);
  
        commit('profileSuccess', profile);
    },
}

const mutations = {
    profileSuccess: (state, profile) => {
        localStorage.setItem('profile', JSON.stringify(profile))
        Vue.set(state, 'profile', profile)
    },
    profileRemove: (state) => {
        localStorage.removeItem('profile')
        Vue.set(state, 'profile', {})
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
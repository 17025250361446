<template>
	<!-- <v-card @click="link && $router.push(link)" elevation="4"> -->
	<v-card v-on="link ? { click: () => $router.push(link) } : {}" elevation="4" class="mt-3">
		<div v-if="threeNextsMeetingsStatus == '(File d\'attente)'" style="position: absolute; top: 30px; left: 70%; transform: translateX(-50%); z-index: 1;">
			<v-img :src="require('@/assets/images/waiting_list.png')" width="200px" alt="OnCOGITE"></v-img>
		</div>
		<v-card-title style="word-break: break-word" class="font-weight-bold" v-if="!options.hideTitle">
			<v-row>
				<v-col cols="8">
					<span>{{ value.name }}</span>
				</v-col>
				<v-col cols="4">
					<v-spacer></v-spacer>
					<div class="text-right">
						<v-icon v-if="type == 'onsite'" color="primary" size="40px">$onsite_workshop</v-icon>
						<v-icon color="primary" size="45px">$remote_workshop</v-icon>	
					</div>
				</v-col>
			</v-row>
		</v-card-title>

		<v-card-text>
			<div v-if="showComplete">
				Atelier <b>{{ value.type_name }}</b>
				<br />
				Atelier <b v-if="value.active">visible</b><b v-else>désactivé</b>
			</div>
			<v-list>
				<b  v-if="threeNextsMeetingsStatus != '(File d\'attente)'" style="font-size: 20px">{{ threeNextsMeetingsStatus }}</b>
				<p class="d-flex" v-if="value.next_meeting">
					{{ $moment(value.next_meeting.start).format("dddd HH[h]mm") }}
					- {{ $moment(value.next_meeting.end).format("HH[h]mm") }}
				</p>

				<v-list-item class="pa-0" three-line>
					<v-list-item-icon class="mr-3">
						<v-icon :color="color" large>person</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-subtitle class="mb-2">Professionnel</v-list-item-subtitle>
						<v-list-item-title>
							{{ value.user.firstname }}
							<!-- <br />
							<v-icon :color="color" small>phone</v-icon> {{ value.user.phone }}
							<br />
							<v-icon :color="color" small>mail</v-icon> {{ value.user.email }} -->
							<br />
							<div class="mt-6" v-if="value.type == 'onsite' && value.info_address"> <v-icon :color="color" small>place</v-icon>{{ value.info_address }}</div>
							<div v-else><v-icon :color="color" small></v-icon></div>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	components: {
		//
	},
	props: {
		value: {
			type: Object,
			required: true,
		},
		link: {
			type: [String, Object],
			required: false,
		},
		showComplete: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: "primary",
		},
		options: {
			type: Object,
			default() {
				return {};
			},
		},
		type: {
			type: [String],
			required: false,
		},
	},
	data() {
		return {
			descriptionLength: 100,
		};
	},
	mounted() {
		//
	},
	computed: {
		threeNextsMeetingsStatus() {
			if(this.value.three_next_meetings.length <= 0) {
				return "(Pas de séances programées)"
			}
			if(!this.value.three_next_meetings.some(meeting => {
				return meeting.places > meeting.validated_subscribers_count;
			})) {
				return "(File d'attente)"
			}
			return ""
		}
	},
	methods: {
		truncate(string) {
			if (string.length <= this.descriptionLength) return string;
			return string.substr(0, string.lastIndexOf(" ", this.descriptionLength)) + "...";
		},
		// deleteToApi() {
		//     return this.$store.dispatch('workshopDeleteRequest', { id: this.value.id}).then(() => {
		//         this.$emit('deleted', this.value)
		//     });
		// },
	},
};
</script>

import Vue from 'vue'

const state = {
    // 
}

const getters = {
    // 
}

const actions = {
    registerRequest: async ({commit}, queries) => {
        let url = `/api/register`;
 
        return new Promise((resolve, reject) => {
            Vue.axios.post(url, queries)
                .then(result => {
                    resolve(result.data.data)
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': 'Action non autorisée'})
                    reject(err)
                })
        })
    },
}

const mutations = {
    // 
}

export default {
    state,
    getters,
    actions,
    mutations,
}
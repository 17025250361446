import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth'
import register from './modules/register'
import snackbar from './modules/snackbar'
import profile from './modules/profile'
import password from './modules/password'
import user from './modules/user'
import workshop from './modules/workshop'
import navigation from './modules/navigation'
import meeting from './modules/meeting'
import payment from './modules/payment'
import setting from './modules/setting'
import support from './modules/support'
import partner from './modules/partner'
import survey from './modules/survey'
import websocket from './modules/websocket'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        register,
        snackbar,
        profile,
        password,
        user,
        workshop,
        navigation,
        meeting,
        payment,
        setting,
        support,
        partner,
        survey,
        websocket,
    }
});
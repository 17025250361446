<template>
    <div class="d-inline-block">
        <v-btn
            :fab="buttonIcon.length > 0"
            :color="color"
            @click.stop="confirmDialog = true"
            dark
            :small="small"
            :depressed="depressed"
            v-on="on"
            class="mx-1"
        >
            <v-icon v-if="buttonIcon">{{ buttonIcon }}</v-icon>
            <template v-if="buttonText">{{ buttonText }}</template>
        </v-btn>
        <v-dialog
            v-model="confirmDialog"
            width="500"
        >
            <v-card :style="{'border': '1em solid white'}">
                <v-card-text :class="{'headline py-5 px-5': true, 'white--text': $vuetify.theme.dark, 'black--text': !$vuetify.theme.dark}">
                    {{ headline }}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="secondary"
                        @click="confirmDialog = false"
                        class="mx-2 my-2"
                    >
                        Annuler
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        dark
                        @click="toconfirm"
                        class="mx-2 my-2"
                    >
                        Confirmer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    props: {
        on: { type: Object, default() {return {}} },
        color: { type: String, default: 'error' },
        small: { type: Boolean, default: false },
        depressed: { type: Boolean, default: false },
        headline: {type: String, default: 'Merci de confirmer cette action'},
        buttonIcon: {type: String, default: ''},
        buttonText: {type: String, default: ''},
    },
    data () {
        return {
            confirmDialog: false,
        }
    },
    methods: {
        toconfirm() {
            this.confirmDialog = false
            this.$emit('confirmed')
        }
    }

}
</script>
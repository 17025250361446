<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form" class="mb-5">
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-model="user.lastname"
                            outlined
                            label="Nom"
                            :rules="[rules.required]"
                        ></v-text-field>

                        <v-text-field
                            v-model="user.firstname"
                            outlined
                            label="Prénom"
                            :rules="[rules.required]"
                        ></v-text-field>

                        <v-text-field
                            v-model="user.email"
                            outlined
                            label="E-mail"
                            :rules="[rules.required, rules.email]"
                        ></v-text-field>

                        <date-input 
                            v-model="user.birthdate" 
                            label="Date de naissance" 
                            @save="date => user.birthdate = date"
                            required
                        />

                        <v-text-field
                            v-model="user.street"
                            outlined
                            label="Adresse"
                            :rules="[rules.required]"
                            hide-details="auto"
                        ></v-text-field>

                        <v-row>
                            <v-col cols="12" :md="4">
                                <v-text-field
                                    v-model="user.zip"
                                    outlined
                                    label="Code postal"
                                    :rules="[rules.required]"
                                    @change="fetchCities"
                                    clearable
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" :md="8">
                                <v-autocomplete
                                    v-model="user.city"
                                    :items="allCities"
                                    item-text="nom"
                                    item-value="nom"
                                    label="Ville"
                                    :loading="citiesLoading"
                                    :disabled="citiesLoading"
                                    outlined
                                    :rules="[rules.required]"
                                    hide-details="auto"
                                    no-data-text="Aucune ville trouvée"
                                    @change="fetchZip"
                                    return-object
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-text-field
                            v-model="user.phone"
                            outlined
                            label="Téléphone"
                            :rules="[rules.required]"
                            @input="formatPhone"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <!-- <div v-if="action == 'create'">
                            <v-text-field
                                v-model="user.password"
                                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                label="Mot de passe"
                                :rules="[rules.required, rules.minlength]"
                                counter
                                @click:append="toggleShowPassword"
                                :type="showPassword ? 'text' : 'password'"
                                hint="8 caractères min."
                            ></v-text-field>

                            <v-text-field
                                v-model="user.confirm"
                                :append-icon="showConfirm ? 'visibility' : 'visibility_off'"
                                label="Confirmation du mot de passe"
                                :rules="[rules.required]"
                                counter
                                @click:append="toggleShowConfirm"
                                :type="showConfirm ? 'text' : 'password'"
                            ></v-text-field>
                        </div> -->

                        <div v-if="action == 'update'">
                            <v-text-field
                                v-model="user.old_password"
                                :append-icon="showOldPassword ? 'visibility' : 'visibility_off'"
                                label="Ancien mot de passe"
                                counter
                                outlined
                                @click:append="showOldPassword = !showOldPassword"
                                :type="showOldPassword ? 'text' : 'password'"
                            ></v-text-field>

                            <v-text-field
                                v-model="password"
                                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                label="Mot de passe"
                                :rules="[rules.minlength]"
                                counter
                                outlined
                                @click:append="showPassword = !showPassword"
                                :type="showPassword ? 'text' : 'password'"
                                hint="8 caractères min."
                            ></v-text-field>

                            <v-text-field
                                v-model="confirm"
                                :append-icon="showConfirm ? 'visibility' : 'visibility_off'"
                                label="Confirmation du mot de passe"
                                counter
                                outlined
                                @click:append="showConfirm = !showConfirm"
                                :type="showConfirm ? 'text' : 'password'"
                            ></v-text-field>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-row justify="center" class="mt-5">
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading" x-large>Sauvegarder</v-btn>
        </v-row>
    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
        action: {
            type: String,
            default: 'update'
        },
    },
    data () {
        return {
            user: Object.assign({}, this.value),
            old_password: '',
            password: '',
            confirm: '',
            showOldPassword: false,
            showPassword: false,
            showConfirm: false,
            updatePassword: false,
            loading: false,
            rules: {
                required: value => !!value || 'Champ requis',
                minlength: value => !value || (value && value.length >= 8) || '8 caractères min.',
                email: value => /.+@.+\..+/.test(value) || 'Format invalide',
            },
            allCities: [],
            citiesLoading: false,
        }
    },
    watch: {
        user () {
            this.$refs.form.resetValidation()
        },
    },
    mounted() {
        this.fetchCities()
    },
    methods: {
        formatPhone() {
            this.user.phone = this.user.phone.replace(/\s/g,'').match(/.{1,2}/g).join(' ')
        },
        fetchCities() {
            this.citiesLoading = true
            let url = 'https://geo.api.gouv.fr/communes'
            if (this.user.zip && this.user.zip.length == 5)
                url += '?codePostal='+this.user.zip
            this.$axios.get(url)
                .then((resp) => {
                    this.allCities = resp.data
                    if (this.allCities.length == 1) {
                        this.user.city = this.allCities[0].nom
                        this.user.zip = this.allCities[0].codesPostaux[0]
                    }
                }).finally(() => {
                    this.citiesLoading = false
                })
        },
        fetchZip() {
            if (this.user.city && this.user.city.codesPostaux) {
                this.user.zip = this.user.city.codesPostaux[0]
                this.user.city = this.user.city.nom
            }
        },
        onSubmit () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                if (this.old_password) {
                    this.user.old_password = this.old_password
                }

                if (this.password && this.confirm) {
                    this.user.password = this.password
                    this.user.password_confirmation = this.confirm
                }

                let request = 'userCreateRequest'
                let queries = this.user

                if (this.action === 'update') {
                    request = 'userEditRequest'
                    queries = {id: this.user.id, datas: this.user}
                }

                this.$store.dispatch(request, queries)
                    .then((user) => {
                        this.$refs.form.resetValidation()
                        this.loading = false;
                        this.$store.dispatch('profileRequest').then(() => {
                            this.$store.commit('setSnack', {'type': 'success', 'msg': 'Utilisateur enregistré !'});
                            this.$router.push({name: 'Profile'})
                        })
                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.response.status != 403)
                            this.$store.dispatch('setErrors', err.response.data)
                    })
            }
        },
    }
}
</script>

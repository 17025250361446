<template>
    <div class="edit-user">
        <Navigation/>
        <v-main>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-card elevation="4" color="secondary" dark>
                            <v-card-text>
                                <v-row no-gutters align="center" class="headline">
                                    <v-col cols="auto"><v-icon x-large class="mr-5">account_circle</v-icon></v-col>
                                    <v-col class="font-weight-bold white--text">Modification de votre profil</v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" v-if="loading">
                        <v-skeleton-loader type="article, list-item, list-item, list-item" height="500px"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" v-else>
                        <UserForm ref="UserForm" v-model="user"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>
<script>
import UserForm from '@/components/users/Form'
export default {
    components: {
        UserForm,
    },
    data() {
        return {
            ready: false,
            user: {},
            loading: false,
        }
    },
    mounted() {
        this.getUser()
    },
    methods: {
        getUser() {
            this.loading = true
            this.$store.dispatch('userRequest', {id: this.$store.getters.getProfile.id})
                .then(result => {
                    this.user = result.data
                    this.loading = false
                    this.ready = true
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
    }
}
</script>
